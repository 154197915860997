import React from "react";
import { Articles } from "../types/article";
import { Room } from "../types/room";
import { ArticleGroupItem } from "../Molecules/ArticleGroupItem";
import { ListWrapper } from "../styles/styles";
import { Groups } from "../types/groups";
import { GroupsText } from "../Atoms/GroupsText";
import styled from "styled-components";

const Gap = styled.div`
  margin: 12px;
`;

interface GroupedArticleListingProps {
  articles: Articles;
  room: Room;
  groups?: Groups | undefined;
}

export const GroupedArticleListing = ({
  articles,
  room,
  groups,
}: GroupedArticleListingProps) => {
  if (!groups) {
    return <></>;
  } else {
    return (
      <>
        <GroupsText groupsCount={groups.length} />
        <Gap />
        <ListWrapper>
          {groups.map((group, index) => (
            <ArticleGroupItem
              key={`${room.id}-${group.id}-${index}`}
              group={group}
              articles={articles}
              roomId={room.id}
            />
          ))}
        </ListWrapper>
      </>
    );
  }
};
