import React from "react";
import { useParams } from "react-router";
import { useRoomsAndArticleCount } from "../hooks/useRoomsAndArticleCount";
import { useStoreId } from "../../../core/auth/useLoggedInUser";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { useArticleById } from "../hooks/useArticleById";
import ListView from "@ingka/list-view";
import { SimpleContainer } from "../styles/styles";
import { ArticleListItem } from "../Molecules/ArticleListItem";
import { RoomSettingsPlacement } from "../Molecules/RoomSettingsPlacement";
import { GreyStrip } from "../Atoms/GreyStrip";
import { Trans, useTranslation } from "@coworker/locales";
import {
  getPrimeTasks,
  getSortedRoomsArticleCount,
} from "./ArticleView.helper";
import { CreateTasks } from "../Organisms/CreateTasks";
import { useTasksByArticle } from "../hooks/useTasksByArticle";
import Button from "@ingka/button";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import { ArticleStock } from "../Molecules/ArticleStock";

export function SearchedArticleView() {
  const { push } = useWorkspacesAction();
  const { t } = useTranslation();
  const { productArticleId } = useParams();
  const storeId = useStoreId();

  const { data: roomsArticleCount, isFetching: racFetching } =
    useRoomsAndArticleCount(productArticleId, storeId);
  const { data: searchedArticle, isFetching: saFetching } = useArticleById(
    roomsArticleCount?.[0]?.article_id
  );
  const { tasks } = useTasksByArticle(storeId, productArticleId);

  const fetching = saFetching || racFetching;

  if (!fetching && !searchedArticle)
    return (
      <SimpleContainer
        $direction={"column"}
        onClick={() => {
          push("/roomsettings");
        }}
      >
        <Trans values={{ article: productArticleId }}>
          articleDoesNotExistInRoomSettingString
        </Trans>
        <Button
          type={"primary"}
          text={t("goBackToRoomListingString")}
          onClick={() => {
            push("/roomsettings");
          }}
        />
      </SimpleContainer>
    );

  if (racFetching || saFetching) return <></>;

  const sortedRoomsArticleCount = getSortedRoomsArticleCount(
    roomsArticleCount ?? []
  );
  const primeTasks = getPrimeTasks(roomsArticleCount ?? [], tasks);

  return (
    <>
      {searchedArticle && (
        <FullScreenPopup
          appBarConfig={{
            title: searchedArticle.itemName,
            actions: [],
          }}
          noPadding
        >
          <ListView id="searched-article-list-view" size="small">
            <ArticleListItem
              article={{ ...searchedArticle, nbrArticles: 0 }}
              hideChevron={true}
            />

            <RoomSettingsPlacement
              roomsArticleCount={sortedRoomsArticleCount}
            />

            <ArticleStock
              productArticleId={searchedArticle.productArticleId}
              productArticleType={searchedArticle.productArticleType}
            />

            <GreyStrip text={t("foundInAllRoomSettingsString")} />

            <CreateTasks
              roomsArticleCount={sortedRoomsArticleCount || []}
              tasks={primeTasks}
            />
          </ListView>
        </FullScreenPopup>
      )}
    </>
  );
}
