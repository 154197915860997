import React from "react";
import ListView from "@ingka/list-view";
import { RoomListItem } from "../Molecules/RoomListItem";
import { AlertResponse, Room } from "../types/room";
import { TaskLinks } from "../types/taskLink";

interface RoomsViewRoomsListProps {
  rooms: Room[] | undefined;
  taskLinks: TaskLinks;
  alerts: AlertResponse[] | undefined;
}

export function RoomsViewRoomsList({
  rooms,
  taskLinks,
  alerts,
}: RoomsViewRoomsListProps) {
  return (
    <>
      <ListView id="rooms-list">
        {rooms &&
          rooms
            .sort((a, b) => a.name.localeCompare(b.name))

            .map((room) => (
              <RoomListItem
                key={room.id}
                room={room}
                taskLinks={taskLinks}
                alerts={alerts || []}
              />
            ))}
      </ListView>
    </>
  );
}
