import React, { useState } from "react";
import { Room } from "../../types/room";
import { useRoomsByStoreId } from "../../hooks/useRoomsByStoreId";
import Modal, { ModalFooter, ModalHeader, Sheets } from "@ingka/modal";
import Button from "@ingka/button";
import { useTranslation } from "@coworker/locales";
import {
  checkEmptyRoomName,
  checkRoomName,
  EmptyRoom,
} from "../SettingsForm.helper";
import { MessageModal } from "./MessageModal";
import { OkCancelModal } from "./OkCancelModal";
import { SettingsForm } from "../SettingsForm";
import { useCreateRoom } from "../../hooks/useCreateRoom";

interface CreateSettingsModalProps {
  storeId: string;
  isVisible: boolean;
  onClose: (roomId?: string) => void;
}

export const CreateSettingsModal = function ({
  storeId,
  isVisible,
  onClose,
}: CreateSettingsModalProps) {
  const { t } = useTranslation();
  const [showModalMessage, setShowModalMessage] = useState<string>("");
  const [showConfirmCloseModal, setShowConfirmCloseModal] = useState(false);
  const [roomIsUpdated, setRoomIsUpdated] = useState(false);
  const [room, setRoom] = useState<Room>({ ...EmptyRoom, store_id: storeId });
  const { data: rooms } = useRoomsByStoreId(storeId);
  const createRoomMutation = useCreateRoom();

  const onSubmit = async () => {
    try {
      const roomId = await createRoomMutation.mutateAsync(room);
      onClose(roomId?.room_id);
    } catch (error) {
      console.log(error);
      setShowModalMessage(t("couldNotCreateRoomTryAgainString"));
    }
  };

  const onCloseWarning = () => {
    if (roomIsUpdated) {
      setShowConfirmCloseModal(true);
    } else {
      onClose();
    }
  };

  const saveRoomChanges = (saveRoom: Room) => {
    setRoomIsUpdated(true);
    setRoom(saveRoom);
  };

  if (!room) {
    return <></>;
  }

  const submitDisabled =
    !rooms ||
    checkEmptyRoomName(room) ||
    checkRoomName(room, rooms) ||
    !roomIsUpdated;

  return (
    <>
      <Modal visible={isVisible} handleCloseBtn={onCloseWarning}>
        <Sheets
          labelledById="edit-room-setting"
          header={<ModalHeader title={t("createRoomSettingsString")} />}
          footer={
            <ModalFooter>
              <Button
                type="primary"
                onClick={onSubmit}
                disabled={submitDisabled}
                aria-label={t("create4String")}
              >
                {t("create4String")}
              </Button>
            </ModalFooter>
          }
        >
          <SettingsForm
            room={room}
            articles={[]}
            onSave={saveRoomChanges}
            showUpdateFields={false}
          />
        </Sheets>
      </Modal>
      {!!showModalMessage && (
        <MessageModal
          message={showModalMessage}
          closeModal={() => {
            setShowModalMessage("");
          }}
        />
      )}
      {showConfirmCloseModal && (
        <OkCancelModal
          onOk={() => {
            setShowConfirmCloseModal(false);
            onClose();
            setRoom({ ...EmptyRoom, store_id: storeId });
          }}
          onCancel={() => {
            setShowConfirmCloseModal(false);
          }}
          title={t("confirmCloseString")}
          text={t("allChangesLostCloseString")}
        />
      )}
    </>
  );
};
