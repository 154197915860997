import React from "react";
import { useTranslation } from "@coworker/locales";
import { useQueryClient } from "@tanstack/react-query";
import { addArticle } from "../../services/articles.service";
import Modal, { ModalBody, ModalHeader, Sheets } from "@ingka/modal";
import { AggregatedArticle } from "../../hooks/useAggregatedArticles";
import { Room } from "../../types/room";
import { QueryKeys } from "../../hooks/queryKeys";
import { ArticleListItem } from "../../Molecules/ArticleListItem";
import { GroupQuantityRow } from "../../Molecules/GroupQuantityRow";
import { Article } from "../../types/article";
import { sortArticlesByGroup } from "../../Articles/ArticleView.helper";

interface EditGroupQuantityModalProps {
  aggregatedArticle: AggregatedArticle;
  room: Room;
  onClose: () => void;
}

export const EditGroupQuantityModal = ({
  aggregatedArticle,
  onClose,
  room,
}: EditGroupQuantityModalProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [articles, setArticles] = React.useState<Article[]>([]);

  if (articles.length === 0) {
    const hasNoneGroup = aggregatedArticle.aggregatedArticles.find(
      (a) => !a.groupName
    );
    if (!hasNoneGroup) {
      const articleCopyBase = aggregatedArticle.aggregatedArticles.find(
        (_a) => true
      );
      setArticles(
        sortArticlesByGroup([
          {
            ...(articleCopyBase as Article),
            nbrArticles: 0,
            groupId: undefined,
            groupName: undefined,
          },
          ...aggregatedArticle.aggregatedArticles,
        ])
      );
    } else
      setArticles(sortArticlesByGroup(aggregatedArticle.aggregatedArticles));
  }

  const invalidateQueries = async () => {
    await Promise.all([
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.ArticlesByGroup],
        refetchType: "all",
      }),
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.ArticlesByRoom],
        refetchType: "all",
      }),
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.ArticlesByRoomAndProductArticleId],
        refetchType: "all",
      }),
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.RoomsAndArticleCount],
      }),
    ]);
  };

  const addArticleWithNoGroup = async (article: Article) => {
    const articleIds = await addArticle({
      ...article,
      groupId: undefined,
      nbrArticles: 1,
    });
    if ((articleIds?.length ?? 0) > 0) {
      const articleId = articleIds?.shift();
      const articleWithNoGroup = articles.find((a) => !a.groupId);
      if (articleWithNoGroup && articleId) {
        articleWithNoGroup.id = articleId.id;
        articleWithNoGroup.nbrArticles = 1;

        setArticles(
          articles.map((article) => {
            return { ...article };
          })
        );
      }
    }
  };

  return (
    <Modal
      handleCloseBtn={async () => {
        await invalidateQueries();
        onClose();
      }}
      visible={true}
    >
      <Sheets
        alignment="right"
        aria-label="Accessibility header for a modal"
        footer={null}
        header={
          <ModalHeader
            ariaCloseTxt="Close edit quantity"
            title={t("editQuantityString")}
            closeBtnClick={async () => {
              await invalidateQueries();
              onClose();
            }}
            style={{ borderBottom: "1px solid var(--grey200)" }}
          />
        }
        size="small"
      >
        <ModalBody style={{ padding: "0px" }}>
          <ArticleListItem article={aggregatedArticle} hideChevron={true} />
          {articles.map((article, index) => (
            <GroupQuantityRow
              key={index}
              index={index}
              room={room}
              article={article}
              addArticleWithNoGroup={addArticleWithNoGroup}
            />
          ))}
        </ModalBody>
      </Sheets>
    </Modal>
  );
};
