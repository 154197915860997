import React from "react";
import { Article } from "../../types/article";
import { ModalTypes } from "../../types/views";
import { SelectedArticlesActionsModal } from "./SelectedArticlesActionsModal";
import { PTagArticleSummaryForClipboardModal } from "./PTagArticleSummaryForClipboardModal";
import { Group, Groups } from "../../types/groups";
import { CreateGroupModal } from "./CreateGroupModal";
import { AddToGroupModal } from "./AddToGroupModal";
import { GroupActionsModal } from "./GroupActionModal";
import { EditGroupModal } from "./EditGroupModal";
import { DeleteGroupModal } from "./DeleteGroupModal";
import { DeleteSelectedArticlesModal } from "./DeleteSelectedArticlesModal";
import { FooterButtonActionsModal } from "./FooterButtonActionModal";

interface RoomArticlesModalsProps {
  selectedArticles: Article[];
  allArticles: Article[];
  currentModalType: ModalTypes;
  onModalTypeChange: (modalType: ModalTypes) => void;
  groups: Groups;
  storeId: string;
  roomId: string;
  currentGroup: Group;
}

export const RoomArticlesModals = ({
  selectedArticles,
  allArticles,
  currentModalType,
  onModalTypeChange,
  groups,
  storeId,
  roomId,
  currentGroup,
}: RoomArticlesModalsProps) => {
  return (
    <>
      {/* Main menus for either selected articles actions and group actions */}
      <SelectedArticlesActionsModal
        selectedArticles={selectedArticles}
        modalVisibility={
          currentModalType === ModalTypes.SELECTED_ARTICLES_ACTIONS
        }
        onTriggerNewModal={(modalType: ModalTypes) => {
          onModalTypeChange(modalType);
        }}
        groups={groups}
      />
      <GroupActionsModal
        modalVisibility={currentModalType === ModalTypes.GROUP_ACTIONS}
        onTriggerNewModal={(modalType: ModalTypes) => {
          onModalTypeChange(modalType);
        }}
        roomId={roomId}
        currentGroup={currentGroup}
      />
      <FooterButtonActionsModal
        roomId={roomId}
        onTriggerNewModal={onModalTypeChange}
        modalVisibility={currentModalType === ModalTypes.FOOTER_BUTTON_ACTION}
      />

      {/* PTag */}
      <PTagArticleSummaryForClipboardModal
        selectedArticles={selectedArticles}
        modalVisibility={currentModalType === ModalTypes.PTAG_ARTICLE_NUMBERS}
        onTriggerNewModal={(modalType: ModalTypes) => {
          onModalTypeChange(modalType);
        }}
      />
      <PTagArticleSummaryForClipboardModal
        selectedArticles={allArticles.filter(
          (article) => !!currentGroup && article.groupId === currentGroup.id
        )}
        modalVisibility={
          currentModalType === ModalTypes.PTAG_GROUP_ARTICLE_NUMBERS
        }
        onTriggerNewModal={(modalType: ModalTypes) => {
          onModalTypeChange(modalType);
        }}
      />

      {/* Reached from SelectedArticlesActionsModal */}
      <CreateGroupModal
        modalVisibility={currentModalType === ModalTypes.CREATE_GROUP}
        roomId={roomId}
        storeId={storeId}
        groups={groups}
        onTriggerNewModal={(modalType: ModalTypes) => {
          onModalTypeChange(modalType);
        }}
        selectedArticles={selectedArticles}
        allArticles={allArticles}
      />
      <AddToGroupModal
        modalVisibility={currentModalType === ModalTypes.ADD_TO_GROUP}
        groups={groups}
        onTriggerNewModal={(modalType: ModalTypes) => {
          onModalTypeChange(modalType);
        }}
        selectedArticles={selectedArticles}
        allArticles={allArticles}
      />
      <DeleteSelectedArticlesModal
        modalVisibility={
          currentModalType === ModalTypes.DELETE_SELECTED_ARTICLES
        }
        selectedArticles={selectedArticles}
        roomId={roomId}
        onTriggerNewModal={(modalType: ModalTypes) => {
          onModalTypeChange(modalType);
        }}
        allArticles={allArticles}
      />

      {/* Reached from GroupActionsModal */}
      <EditGroupModal
        modalVisibility={currentModalType === ModalTypes.EDIT_GROUP_NAME}
        currentGroup={currentGroup}
        onTriggerNewModal={(modalType: ModalTypes) => {
          onModalTypeChange(modalType);
        }}
        groups={groups}
      />
      <DeleteGroupModal
        modalVisibility={currentModalType === ModalTypes.DELETE_GROUP}
        roomId={roomId}
        allArticles={allArticles}
        currentGroup={currentGroup}
        onTriggerNewModal={(modalType: ModalTypes) => {
          onModalTypeChange(modalType);
        }}
      />
    </>
  );
};
