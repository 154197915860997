import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "@coworker/locales";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { useTasksByStore } from "../hooks/useTasksByStore";
import useFlag, { FLAGS } from "../../../hooks/useFlag";
import { useStoreId } from "../../../core/auth/useLoggedInUser";
import { RoomsViewSkeleton } from "../Skeletons/RoomsViewSkeleton";
import { useAlertsByStore } from "../hooks/useAlertsByStore";
import { useRoomsByStoreId } from "../hooks/useRoomsByStoreId";
import { RoomsViewRoomsList } from "../Organisms/RoomsViewRoomsList";
import { RoomsViewSearchList } from "../Organisms/RoomsViewSearchList";
import { RoomsCountText } from "../Atoms/RoomsAmountText";
import { MessageAndButton } from "../Molecules/MessageAndButton";
import Button from "@ingka/button";
import { CreateSettingsModal } from "../Organisms/Modals/CreateSettingsModal";
import Search from "@ingka/search";

const ScrollContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding-left: 64px;
  padding-right: 64px;
`;

const ToolBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 40px;
  padding-bottom: 40px;
`;

const HeaderTitleText = styled.div`
  font-size: 24px;
  font-weight: 700;
`;

const HorizontalLine = styled.hr`
  height: 1px;
  background-color: #dfdfdf;
`;

export function RoomsViewDesktop() {
  const { t } = useTranslation();
  const { push } = useWorkspacesAction();
  const storeId = useStoreId();
  const { data: rooms, isLoading: isRoomsLoading } = useRoomsByStoreId(storeId);
  const { data: alerts } = useAlertsByStore(storeId);

  const tasks = useTasksByStore(storeId);
  const hideForGermanyUsers = useFlag(FLAGS.GERMANY_ROLLOUT);

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [showModal, setShowModal] = useState(false);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const onClear = () => {
    setSearchQuery("");
  };

  if (hideForGermanyUsers || isRoomsLoading) {
    return (
      <>
        <ToolBarContainer>
          <HeaderTitleText>{t("roomSettingsString")}</HeaderTitleText>
        </ToolBarContainer>
        <RoomsViewSkeleton />
      </>
    );
  }

  // No rooms
  if ((rooms?.length ?? 0) === 0) {
    return (
      <>
        <MessageAndButton
          keyForMessageText={"noRoomSettingsCreatedString"}
          keyForButtonText={"addRoomSettingsString"}
          buttonAction={async () => {
            setShowModal(true);
          }}
        />
        <CreateSettingsModal
          storeId={storeId}
          isVisible={showModal}
          onClose={(roomId) => {
            setShowModal(false);
            if (roomId) {
              push(`/roomsettings/tabs/${roomId}`);
            }
          }}
        />
      </>
    );
  }

  return (
    <>
      <ScrollContainer>
        <ToolBarContainer>
          <HeaderTitleText>{t("roomSettingsString")}</HeaderTitleText>
          <Button
            aria-label={t("addRoomSettingsString")}
            text={t("addRoomSettingsString")}
            type="primary"
            onClick={() => {
              setShowModal(true);
            }}
          />
        </ToolBarContainer>

        <HorizontalLine />

        <ToolBarContainer>
          <div></div>
          <Search
            style={{ width: "310px" }}
            id="room-search"
            value={searchQuery ?? ""}
            onChange={onChange}
            onClear={onClear}
            placeholder={t("roomsViewSearch")}
            ariaLabel={t("roomsViewSearch")}
          />
        </ToolBarContainer>

        {searchQuery.length > 1 ? (
          <RoomsViewSearchList
            rooms={rooms}
            query={searchQuery}
            taskLinks={tasks.taskLinks}
            alerts={alerts}
            storeId={storeId}
          />
        ) : (
          <>
            <RoomsCountText roomSettings={rooms?.length || 0} />
            <RoomsViewRoomsList
              rooms={rooms}
              taskLinks={tasks.taskLinks}
              alerts={alerts}
            />
          </>
        )}
      </ScrollContainer>

      <CreateSettingsModal
        storeId={storeId}
        isVisible={showModal}
        onClose={(roomId) => {
          setShowModal(false);
          if (roomId) {
            push(`/roomsettings/tabs/${roomId}`);
          }
        }}
      />
    </>
  );
}
