import React from "react";
import { useTranslation } from "@coworker/locales";
import { useUpdateArticle } from "../hooks/useUpdateArticle";

import styled from "styled-components";
import { SimpleContainer } from "../styles/styles";
import { Text } from "../Atoms/Text";
import QuantityStepper from "@ingka/quantity-stepper";
import Button from "@ingka/button";

import { Article } from "../types/article";
import { Room } from "../types/room";

const RowContainerBorderBottom = styled(SimpleContainer)`
  border-bottom: 1px solid var(--grey200);
`;
const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
`;

interface GroupQuantityRowProps {
  index: number;
  room: Room;
  article: Article;
  addArticleWithNoGroup: (article: Article) => void;
}

export function GroupQuantityRow({
  index,
  room,
  article,
  addArticleWithNoGroup,
}: GroupQuantityRowProps) {
  const { t } = useTranslation();
  const updateArticle = useUpdateArticle(article.id, false);

  const handleQuantityChange = async (nbrArticles: number) => {
    if (article.nbrArticles === 0) return;
    try {
      await updateArticle.mutateAsync({ ...article, nbrArticles });
    } catch (error) {
      console.error("Error updating article", error);
      alert(t("backendOperationFailedString"));
    }
  };

  return (
    <RowContainerBorderBottom key={index} $direction={"row"}>
      <LeftContainer>
        <Text
          text={article.groupName ? t("Group") : t("roomSettingsString")}
          bold
        />
        <Text text={article.groupName ? article.groupName : room.name} />
      </LeftContainer>
      {article.nbrArticles === 0 ? (
        <Button
          type="secondary"
          text={t("addString")}
          small
          style={{ width: "120px" }}
          onClick={() => addArticleWithNoGroup(article)}
        />
      ) : (
        <QuantityStepper
          small={true}
          ariaDescribedBy="Type in a number or use arrow up or arrow down to change the quantity"
          ariaDescribedById="helper"
          ariaLabelDecrease="Decrease value"
          ariaLabelIncrease="Increase value"
          ariaLabelInput="Enter quantity"
          defaultValue={article.nbrArticles}
          maxVal={99}
          minVal={1}
          onQuantityChange={(changedNbrArticles) =>
            changedNbrArticles !== article.nbrArticles &&
            handleQuantityChange(changedNbrArticles)
          }
        />
      )}
    </RowContainerBorderBottom>
  );
}
