import React from "react";
import styled from "styled-components";
import { useTranslation } from "@coworker/locales";
import GraphCardWrapper from "./GraphCardWrapper";
import { useInsightsFetchParams } from "./helpers";
import { useStoreId } from "../../core/auth/useLoggedInUser";
import Filters from "./Filters";
import ProductScroller from "./ProductScroller";
import { isRDTDevice } from "@coworker/reusable";
import { getInsightsProductPath } from "../../helpers/insightsHelper";
import { InsightsStoreOverviewBox } from "./InsightsStoreOverviewBox";
import { useMyStore } from "../../hooks/useMyStore";
import { useLanguage } from "../../core/hooks/useLanguage";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import SSRIcon from "@ingka/ssr-icon";
import DownloadIcon from "@ingka/ssr-icon/paths/arrow-down-to-base";
import { useGenerateReport } from "@coworker/apprestructured/src/home/hooks/useGenerateReport";
import { LoaderIcon } from "@coworker/reusable/Loader";

const HeadingFollowerContainer = styled.div`
  position: sticky;
  width: 100%;
  z-index: var(--z-heading-follower);
  border-bottom: 1px solid var(--grey100);
  top: 0px;
`;

const Content = styled.div`
  border: 8px solid var(--grey100);
  overflow: hidden;
`;

const Spacer = styled.div`
  height: 8px;
  background-color: var(--grey100);
`;

const Name = styled.div`
  overflow: hidden;
  word-wrap: break-word;
  line-height: 36px;
  font-weight: bold;
  min-height: 86px;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  padding: 12px 24px;
  background-color: var(--grey100);
`;

const Store = () => {
  const { t } = useTranslation();
  const store_id = useStoreId();
  const storeName = useMyStore()?.name || "";
  const { isReportLoading, fetchReport } = useGenerateReport();
  const language = useLanguage();
  const isRDT = isRDTDevice();
  const [dataFetchParams, onChangeInsightsParams, isFetchingSavedFilters] =
    useInsightsFetchParams({
      store_id,
      hfb_value: "ALL",
    });

  return (
    <FullScreenPopup
      noPadding
      appBarConfig={{
        title: t("storeOverviewString"),
        actions: [
          !isReportLoading &&
            !isRDT && {
              name: "exportExcel",
              icon: <SSRIcon paths={DownloadIcon} />,
              position: "right",
              onClick: () => {
                fetchReport({
                  dataFetchParams: {
                    ...dataFetchParams,
                    isFetchingSavedFilters,
                  },
                  extra: {
                    insightsProductURL: getInsightsProductPath(),
                    locale: language,
                  },
                  type: "insights",
                });
              },
            },
          isReportLoading && {
            name: "exportExcelLoading",
            icon: <LoaderIcon />,
            position: "right",
            onClick: () => {},
          },
        ],
      }}
    >
      <HeadingFollowerContainer data-testid="filterContainer">
        <Filters
          dataFetchParams={{ ...dataFetchParams, isFetchingSavedFilters }}
          onChangeInsightsParams={onChangeInsightsParams}
          level={1}
        />
      </HeadingFollowerContainer>
      <InsightsStoreOverviewBox />
      <Name data-testid="store_name">{storeName}</Name>
      <Content data-testid="storeOverview">
        <GraphCardWrapper
          dataFetchParams={{ ...dataFetchParams, isFetchingSavedFilters }}
          onChangeInsightsParams={onChangeInsightsParams}
        />
        <Spacer />
        <ProductScroller
          order={"most"}
          limit={10}
          testId="storeOverviewHighestProducts"
          dataFetchParams={{ ...dataFetchParams, isFetchingSavedFilters }}
          onChangeInsightsParams={onChangeInsightsParams}
        />
        <Spacer />
        <ProductScroller
          order={"least"}
          limit={10}
          testId="storeOverviewLowestProducts"
          dataFetchParams={{ ...dataFetchParams, isFetchingSavedFilters }}
          onChangeInsightsParams={onChangeInsightsParams}
        />
      </Content>
    </FullScreenPopup>
  );
};

export default Store;
