import React, { forwardRef } from "react";
import Search from "@ingka/search";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/button/dist/style.css";
import "@ingka/search/dist/style.css";
import "@ingka/focus/dist/style.css";

type FixaSearchProps = {
  id: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  onClear: (e: React.MouseEvent<HTMLButtonElement>) => void;
  value?: string;
  ariaLabel?: string;
  "data-testid"?: string;
};

const FixaSearch = forwardRef<HTMLInputElement, FixaSearchProps>(
  (props, ref) => {
    return <Search ref={ref} {...props} />;
  }
);

export default FixaSearch;
