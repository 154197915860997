import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "@coworker/locales";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { useTasksByStore } from "../hooks/useTasksByStore";
import useFlag, { FLAGS } from "../../../hooks/useFlag";
import { useStoreId } from "../../../core/auth/useLoggedInUser";
import { RightArrow } from "../Atoms/Icons/RightArrow";
import { TwoLinedGreyBox } from "../Molecules/TwoLinedGreyBox";
import { RoomsSearchBarCombinedSearch } from "../Molecules/RoomsSearchBarCombinedSearch";
import { RoomsViewSkeleton } from "../Skeletons/RoomsViewSkeleton";
import { TitleHeader } from "../../TestBuy/CommonComponents/TitleHeader/TitleHeader";
import { useAlertsByStore } from "../hooks/useAlertsByStore";
import { useRoomsByStoreId } from "../hooks/useRoomsByStoreId";
import { useActiveRoomsByStoreId } from "../hooks/useActiveRoomsByStoreId";
import { RoomsViewRoomsList } from "../Organisms/RoomsViewRoomsList";
import { RoomsViewSearchList } from "../Organisms/RoomsViewSearchList";
import { RoomsCountText } from "../Atoms/RoomsAmountText";
import { MessageAndButton } from "../Molecules/MessageAndButton";
import Button from "@ingka/button";
import { FixaWindow } from "@coworker/reusable";
import { CreateSettingsModal } from "../Organisms/Modals/CreateSettingsModal";

const editableRoomSettingsSettings = (window as FixaWindow)
  .editableRoomSettingsSettings;

const ScrollContainer = editableRoomSettingsSettings
  ? styled.div`
      flex-grow: 1;
      overflow-y: auto;
      height: calc(100% - 76px);
    `
  : styled.div``;

const ApplicationFooterActionBar = styled.div`
  position: fixed;
  bottom: 68px;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-block: 10px;
  background-color: white;
  border-top: 1px solid var(--grey200);
`;

export function RoomsViewMobile() {
  const { t } = useTranslation();
  const { push } = useWorkspacesAction();
  const storeId = useStoreId();
  const { data: rooms, isLoading: isRoomsLoading } = useRoomsByStoreId(storeId);
  let { activeRooms, isLoading: isActiveRoomsLoading } =
    useActiveRoomsByStoreId(storeId);
  const { data: alerts } = useAlertsByStore(storeId);

  const tasks = useTasksByStore(storeId);
  const hideForGermanyUsers = useFlag(FLAGS.GERMANY_ROLLOUT);

  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const [showModal, setShowModal] = useState(false);

  const handleSearchResults = (query: string | undefined) => {
    setSearchQuery(query);
  };

  if (hideForGermanyUsers || isRoomsLoading || isActiveRoomsLoading) {
    return (
      <>
        <TitleHeader title={t("roomSettingsString")} showSearch={false} />
        <RoomsViewSkeleton />
      </>
    );
  }

  // No active room
  if (!editableRoomSettingsSettings) {
    if ((rooms?.length ?? 0) > 0 && (activeRooms?.length ?? 0) === 0) {
      return (
        <MessageAndButton
          keyForMessageText={"allRoomsInactiveString"}
          keyForButtonText={"gotoAdminString"}
          buttonAction={async () => {
            window.open("https://fixa-admin.ingka.com/roomsettings");
          }}
        />
      );
    }
  } else {
    activeRooms = rooms;
  }

  // No rooms
  if ((rooms?.length ?? 0) === 0) {
    if (editableRoomSettingsSettings) {
      return (
        <>
          <MessageAndButton
            keyForMessageText={"noRoomSettingsCreatedString"}
            keyForButtonText={"addRoomSettingsString"}
            buttonAction={async () => {
              setShowModal(true);
            }}
          />
          <CreateSettingsModal
            storeId={storeId}
            isVisible={showModal}
            onClose={(roomId) => {
              setShowModal(false);
              if (roomId) {
                push(`/roomsettings/tabs/${roomId}`);
              }
            }}
          />
        </>
      );
    } else {
      return (
        <MessageAndButton
          keyForMessageText={"noRoomSettingsCreatedString"}
          keyForButtonText={"gotoAdminString"}
          buttonAction={async () => {
            window.open("https://fixa-admin.ingka.com/roomsettings");
          }}
        />
      );
    }
  }

  return (
    <>
      <ScrollContainer>
        <TitleHeader title={t("roomSettingsString")} showSearch={false} />
        <TwoLinedGreyBox
          tabIndex={0}
          role="button"
          ariaLabel={t("ongoingTasksAriaLabelString", { count: tasks.count })}
          headLine={t("ongoingTasksString")}
          bottomleftText={`${tasks.count}`}
          rightComponent={<RightArrow />}
          onClick={() => {
            push(`/roomsettings/tasks`);
          }}
        />

        <RoomsSearchBarCombinedSearch onSearch={handleSearchResults} />
        {searchQuery ? (
          <RoomsViewSearchList
            rooms={activeRooms}
            query={searchQuery}
            taskLinks={tasks.taskLinks}
            alerts={alerts}
            storeId={storeId}
          />
        ) : (
          <>
            <RoomsCountText roomSettings={activeRooms?.length || 0} />
            <RoomsViewRoomsList
              rooms={activeRooms}
              taskLinks={tasks.taskLinks}
              alerts={alerts}
            />
          </>
        )}
      </ScrollContainer>
      {editableRoomSettingsSettings && (
        <>
          <ApplicationFooterActionBar>
            <Button
              aria-label={t("addRoomSettingsString")}
              text={t("addRoomSettingsString")}
              type="primary"
              onClick={() => {
                setShowModal(true);
              }}
            />
          </ApplicationFooterActionBar>
          <CreateSettingsModal
            storeId={storeId}
            isVisible={showModal}
            onClose={(roomId) => {
              setShowModal(false);
              if (roomId) {
                push(`/roomsettings/tabs/${roomId}`);
              }
            }}
          />
        </>
      )}
    </>
  );
}
