import { useQuery } from "@tanstack/react-query";
import { fetchArticlesInfo } from "../../../hooks/API/tasks.service.helper";
import { QueryKeys } from "./queryKeys";

export const useArticlesInfo = (articleIds: string | undefined) => {
  return useQuery({
    queryKey: [QueryKeys.ArticlesInfo, articleIds],
    queryFn: () => fetchArticlesInfo(articleIds || ""),
    enabled: !!articleIds,
  });
};
