import React, { useState } from "react";
import { useTranslation } from "@coworker/locales";
import { SearchBarContainer } from "../styles/styles";
import FixaSearch from "@coworker/apprestructured/src/shared/wrappers/FixaSearch/FixaSearch";

interface RoomsSearchBarCombinedSearchProps {
  onSearch: (query: string | undefined) => void;
}

export const RoomsSearchBarCombinedSearch = ({
  onSearch,
}: RoomsSearchBarCombinedSearchProps) => {
  const [searchInput, setSearchInput] = useState("");
  const { t } = useTranslation();

  const handleSearch = (value: string) => {
    setSearchInput(value);

    // Require two characters before trigger search
    if (value.length > 1) {
      onSearch(value);
    } else {
      onSearch(undefined);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleSearch(e.target.value);
  };

  const handleClear = () => {
    handleSearch("");
  };

  return (
    <SearchBarContainer>
      <FixaSearch
        id="room-search"
        value={searchInput}
        onChange={handleChange}
        onClear={handleClear}
        placeholder={t("roomsViewSearch")}
        ariaLabel={t("roomsViewSearch")}
      />
    </SearchBarContainer>
  );
};
