import Tabs, { Tab, TabPanel } from "@ingka/tabs";
import React from "react";
import { useTranslation } from "@coworker/locales";
import { Room } from "../types/room";
import { AggregatedArticleListing } from "./AggregatedArticleListing";
import { GroupedArticleListing } from "./GroupedArticleListing";
import { useSearchParams } from "react-router-dom";
import { ArticleIdNbrArticles, Articles } from "../types/article";
import { Groups } from "../types/groups";
import { TabQueryKey, TabIds } from "./RoomTabs.helper";

interface RoomTabsProps {
  room: Room;
  activeArticles: Articles;
  isSelectArticlesMode: boolean;
  getSelectedArticleIds: (
    fetchSelectedArticleIdsFunction: () => ArticleIdNbrArticles[]
  ) => void;
  setSelectArticleMode: () => void;
  allArticlesInRoom: Articles;
  onCurrentTabChange: (tab: string) => void;
  groups: Groups;
  currentTabId?: string | undefined;
}

export const RoomTabs = ({
  room,
  activeArticles,
  isSelectArticlesMode,
  getSelectedArticleIds,
  setSelectArticleMode,
  onCurrentTabChange,
  groups,
  currentTabId,
}: RoomTabsProps) => {
  const { t } = useTranslation();
  const [queryParams] = useSearchParams();
  const tabId = queryParams.get(TabQueryKey);

  if (!!tabId && !currentTabId) onCurrentTabChange(tabId);

  if (!activeArticles || !groups || !tabId) return <></>;

  return (
    <Tabs
      style={{ padding: "0px 16px" }}
      defaultActiveTab={currentTabId ?? tabId}
      tabs={[
        <Tab
          key={TabIds.LIST}
          tabPanelId={TabIds.LIST}
          text={t("articleListString")}
        />,
        <Tab
          key={TabIds.GROUP}
          tabPanelId={TabIds.GROUP}
          text={t("groupsString")}
        />,
      ]}
      onTabChanged={(tab: string) => onCurrentTabChange(tab)}
      tabPanels={[
        <TabPanel key={"article-list-tab-panel"} tabPanelId={TabIds.LIST}>
          <AggregatedArticleListing
            articles={activeArticles}
            room={room}
            groups={groups}
            isSelectArticlesMode={isSelectArticlesMode}
            getSelectedArticleIds={getSelectedArticleIds}
            setSelectArticleMode={setSelectArticleMode}
            allArticlesInRoom={activeArticles}
          />
        </TabPanel>,

        <TabPanel key={"groups-tab-panel"} tabPanelId={TabIds.GROUP}>
          <GroupedArticleListing
            articles={activeArticles}
            room={room}
            groups={groups}
          />
        </TabPanel>,
      ]}
    />
  );
};
