import React from "react";
import styled from "styled-components";
import Button from "@ingka/button";
import switchArticle from "@ingka/ssr-icon/paths/arrow-left-arrow-right";
import trashcan from "@ingka/ssr-icon/paths/trash-can";
import QuantityStepper from "@ingka/quantity-stepper";
import { FixaWindow } from "@coworker/reusable";
import { useTranslation } from "@coworker/locales";

const ButtonWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;
interface ArticleListItemActionRowProps {
  nbrArticles: number;
  onQuantityChange: (value: number) => void;
  selectArticleIdsMode: boolean;
  onReplaceArticle: () => void;
  onDeleteArticle: () => void;
  doUseQuantityStepper?: boolean;
}

export const ArticleListItemActionRow: React.FC<
  ArticleListItemActionRowProps
> = ({
  nbrArticles,
  onQuantityChange,
  selectArticleIdsMode,
  onReplaceArticle,
  onDeleteArticle,
  doUseQuantityStepper = true,
}) => {
  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation(); // This stops the click event from propagating to parent elements
  };
  const { t } = useTranslation();

  const hideActionRow =
    (window as FixaWindow).showNewGrouping && selectArticleIdsMode;

  if (hideActionRow) {
    return <></>;
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          gap: "16px",
          marginTop: "8px",
          marginLeft: "68px",
        }}
        onClick={(e) => {
          handleClick(e);
        }}
      >
        {doUseQuantityStepper ? (
          <QuantityStepper
            small={true}
            ariaDescribedBy="articleQuantityStepper"
            ariaDescribedById="articleQuantityStepper"
            minVal={1}
            maxVal={99}
            defaultValue={nbrArticles}
            onQuantityChange={onQuantityChange}
          />
        ) : (
          <Button
            small={true}
            text={t("editQuantityString")}
            onClick={() => onQuantityChange(nbrArticles)}
          />
        )}
        {!selectArticleIdsMode && (
          <>
            <ButtonWrapper>
              {!(window as FixaWindow).showNewGrouping && (
                <Button
                  small={true}
                  iconOnly={true}
                  ssrIcon={switchArticle}
                  onClick={onReplaceArticle}
                />
              )}
              <Button
                small={true}
                iconOnly={true}
                ssrIcon={trashcan}
                onClick={onDeleteArticle}
              />
            </ButtonWrapper>
          </>
        )}
      </div>
    </>
  );
};
