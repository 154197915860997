import React from "react";
import Pill from "@ingka/pill";
import chevronUpIcon from "@ingka/ssr-icon/paths/chevron-up";
import chevronDownIcon from "@ingka/ssr-icon/paths/chevron-down";
import ListBox, { MenuItem } from "@ingka/list-box";
import { FilterOptionType } from "../../../constants/filterOptions";
import { Trans } from "@coworker/locales";
import { StoreTeam } from "@coworker/types/lib/storeteam";
import {
  ActivityFilterType,
  FilterOptionsKeys,
} from "../../../types/filterOptionsTypes";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/list-box/dist/style.css";
import styles from "../activity-filters.module.css";
import trackerHelper from "@coworker/app/src/helpers/tracker";

interface DropdownPillProps {
  id: Extract<
    FilterOptionsKeys,
    | "assignedTo"
    | "createdBy"
    | "taskType"
    | "sortedBy"
    | "locations"
    | "period"
  >;
  label: string;
  isOpen: boolean;
  onClick: () => void;
  filters: ActivityFilterType;
  setFilters: (value: ActivityFilterType) => void;
  setIsPanelOpen: (value: boolean) => void;
  closeDropdown: () => void;
  options: FilterOptionType[] | StoreTeam[];
  additionalPillAttributes?: Record<string, unknown>;
}

export const DropdownPill = ({
  id,
  label,
  isOpen,
  onClick,
  filters,
  setFilters,
  setIsPanelOpen,
  closeDropdown,
  options,
  additionalPillAttributes,
}: DropdownPillProps) => {
  const formattedLabel = (
    <>
      <Trans>{label}</Trans>
      {id === "sortedBy" && filters.sortedBy && (
        <>
          :{" "}
          <Trans>
            {filters.sortedBy === "createdAtDesc"
              ? "newestFirstString"
              : "oldestFirstString"}
          </Trans>
        </>
      )}
    </>
  );

  return (
    <>
      {isOpen && <div className={styles["backdrop"]} onClick={closeDropdown} />}
      <div className={styles["dropdown-pill-mobile"]}>
        <Pill
          size="small"
          label={formattedLabel}
          selected={!!filters[id]}
          onClick={() => {
            trackerHelper.trackActivityDropdownPillClick(id);
            setIsPanelOpen(true);
          }}
          {...additionalPillAttributes}
        />
      </div>
      <div className={styles["dropdown-pill-desktop"]}>
        <Pill
          size="small"
          label={formattedLabel}
          ssrIcon={isOpen ? chevronUpIcon : chevronDownIcon}
          iconPosition="trailing"
          onClick={() => {
            trackerHelper.trackActivityDropdownPillClick(id);
            onClick();
          }}
          {...additionalPillAttributes}
        />

        <ListBox open={isOpen} value={[filters[id] as string]}>
          {options.map((item) => (
            <MenuItem
              key={item.id}
              onClick={() => {
                trackerHelper.trackActivityDropdownPillValueClick(id, item.id);
                setFilters({
                  ...filters,
                  [id]: filters[id] === item.id ? !item.id : item.id,
                });
              }}
              id={item.id}
              title={
                <Trans>
                  {(item as FilterOptionType).transKey ??
                    (item as StoreTeam).name}
                </Trans>
              }
            />
          ))}
        </ListBox>
      </div>
    </>
  );
};
