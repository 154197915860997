import { hasOutgoingEDS, hasSalesStop } from "../tools";
import {
  Article,
  ArticleIdNbrArticles,
  Articles,
  EdsData,
  SalesStopData,
} from "../types/article";
import { TaskLinks } from "../types/taskLink";
import { sortArticlesByItemName } from "../../../helpers/sorters";

export const isMainArticle = (
  articleId: string,
  roomsMainArticleId: string,
  roomsSecondaryMainArticleId: string
): boolean => {
  return (
    articleId === roomsMainArticleId ||
    articleId === roomsSecondaryMainArticleId
  );
};

export const isBackupForProduct = (
  articleId: string,
  roomsBackupArticleId: string,
  roomsSecondaryBackupArticleId: string
): boolean => {
  return (
    articleId === roomsBackupArticleId ||
    articleId === roomsSecondaryBackupArticleId
  );
};

export const hasOngoingTask = (articleId: string, taskLinks: TaskLinks) =>
  taskLinks.some((task) => task.articleId === articleId);

const sortMarkedArticles = (
  a: Article,
  b: Article,
  roomsMainArticleId: string,
  roomsSecondaryMainArticleId: string,
  roomsBackupArticleId: string,
  roomsSecondaryBackupArticleId: string
): number => {
  if (a.id === roomsMainArticleId) return -1;
  if (b.id === roomsMainArticleId) return 1;
  if (a.id === roomsSecondaryMainArticleId) return -1;
  if (b.id === roomsSecondaryMainArticleId) return 1;
  if (a.id === roomsBackupArticleId) return -1;
  if (b.id === roomsBackupArticleId) return 1;
  if (a.id === roomsSecondaryBackupArticleId) return -1;
  if (b.id === roomsSecondaryBackupArticleId) return 1;
  return 0;
};

export const sortArticles = (
  articles: Articles,
  roomsMainArticleId: string,
  roomsSecondaryMainArticleId: string,
  roomsBackupArticleId: string,
  roomsSecondaryBackupArticleId: string,
  taskLinks: TaskLinks,
  edsData?: EdsData[],
  salesStopData?: SalesStopData[]
) => {
  return articles
    .sort((a, b) => {
      const upOrDown = sortMarkedArticles(
        a,
        b,
        roomsMainArticleId,
        roomsSecondaryMainArticleId,
        roomsBackupArticleId,
        roomsSecondaryBackupArticleId
      );
      if (upOrDown !== 0) return upOrDown;

      const aHasTask = hasOngoingTask(a.id, taskLinks);
      const bHasTask = hasOngoingTask(b.id, taskLinks);
      const aHasEDS = edsData ? hasOutgoingEDS(edsData, a) : false;
      const bHasEDS = edsData ? hasOutgoingEDS(edsData, b) : false;
      const aHasSalesStop = salesStopData
        ? hasSalesStop(salesStopData, a)
        : false;
      const bHasSalesStop = salesStopData
        ? hasSalesStop(salesStopData, b)
        : false;

      if (aHasSalesStop && !bHasSalesStop) return -1;
      if (!aHasSalesStop && bHasSalesStop) return 1;
      if (aHasEDS && !bHasEDS) return -1;
      if (!aHasEDS && bHasEDS) return 1;
      if (aHasTask && !bHasTask) return -1;
      if (!aHasTask && bHasTask) return 1;

      return a.itemName > b.itemName ? 1 : -1;
    })
    .sort(sortArticlesByItemName);
};

export const selectArticleIdNbrArticles = (
  articleIdNbrArticles: ArticleIdNbrArticles,
  selectedArticleIdsNbrArticles: ArticleIdNbrArticles[],
  setSelectedArticleIdsNbrArticles: (value: ArticleIdNbrArticles[]) => void
) => {
  const foundArticleIdNbrArticles = selectedArticleIdsNbrArticles.find(
    (item) => item.articleId === articleIdNbrArticles.articleId
  );
  if (
    !!foundArticleIdNbrArticles &&
    foundArticleIdNbrArticles?.nbrArticles !== articleIdNbrArticles.nbrArticles
  ) {
    foundArticleIdNbrArticles.nbrArticles = articleIdNbrArticles.nbrArticles;
    return;
  }

  let newSelectedArticleIds = [];
  if (!!foundArticleIdNbrArticles) {
    newSelectedArticleIds = selectedArticleIdsNbrArticles.filter(
      (item) => item.articleId !== articleIdNbrArticles.articleId
    );
  } else {
    newSelectedArticleIds = [
      ...selectedArticleIdsNbrArticles,
      articleIdNbrArticles,
    ];
  }

  setSelectedArticleIdsNbrArticles(newSelectedArticleIds);
};
