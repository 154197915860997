import React from "react";
import styles from "./app-bar.module.css";

export type AppBarAction = {
  name: string;
  icon: React.JSX.Element;
  onClick: React.MouseEventHandler;
  position: "left" | "right";
  disabled?: boolean;
  testId?: string;
};

export interface AppBarProps {
  title: string | React.ReactNode;
  subtitle?: string;
  actions?: AppBarAction[];
}

export const AppBar = ({ title, subtitle, actions = [] }: AppBarProps) => {
  return (
    <div
      className={`${styles["app-bar"]} ${
        subtitle ? styles["with-subtitle"] : ""
      }`}
    >
      <div className={styles["actions-left"]}>
        {actions
          .filter((action) => action.position === "left")
          .map((action, index) => (
            <div
              key={index}
              className={styles["icon-wrapper"]}
              onClick={action.onClick}
            >
              {action.icon}
            </div>
          ))}
      </div>
      <div className={styles["center"]}>
        <div className={styles["title"]}>{title}</div>
        {subtitle && <div className={styles["subtitle"]}>{subtitle}</div>}
      </div>
      <div className={styles["actions-right"]}>
        {actions
          .filter((action) => action.position === "right")
          .map((action, index) => (
            <div
              key={index}
              className={styles["icon-wrapper"]}
              onClick={action.onClick}
            >
              {action.icon}
            </div>
          ))}
      </div>
    </div>
  );
};
