import React from "react";
import { Articles } from "../types/article";
import { Group } from "../types/groups";
import { ArticlesImagesOverview } from "./ArticlesImagesOverview";
import {
  ArticleGroupRowContainer,
  CenterContainer,
  RightContainer,
} from "../styles/styles";
import { RightArrow } from "../Atoms/Icons/RightArrow";
import { Text } from "../Atoms/Text";
import { useTranslation } from "@coworker/locales";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";

interface ArticleGroupItemProps {
  group: Group;
  articles: Articles;
  roomId: string;
}

export const ArticleGroupItem = ({
  articles,
  group,
  roomId,
}: ArticleGroupItemProps) => {
  const { t } = useTranslation();
  const { push } = useWorkspacesAction();

  const articlesInGroup = articles.filter(
    (article) => article.groupId === group.id
  );
  const articleCount = articlesInGroup.length;
  const pieceCount = articlesInGroup.reduce(
    (accumulator, currentValue) => (accumulator += currentValue.nbrArticles),
    0
  );

  return (
    <ArticleGroupRowContainer>
      <CenterContainer
        $centerText={false}
        onClick={() => {
          push(`/roomsettings/groups/${group.id}?roomId=${roomId}`);
        }}
      >
        <Text text={group.name} bold />
        <Text
          text={`${t("countArticlesString", {
            count: articleCount,
          })}, ${t("piecesCountString", { count: pieceCount })}`}
        />
        {articleCount > 0 && (
          <ArticlesImagesOverview articles={articlesInGroup} />
        )}
      </CenterContainer>
      <RightContainer>
        <RightArrow />
      </RightContainer>
    </ArticleGroupRowContainer>
  );
};
