import React, { useState } from "react";
import FixaSearch from "@coworker/apprestructured/src/shared/wrappers/FixaSearch/FixaSearch";
import { Articles } from "../types/article";
import { SearchBarContainer } from "../styles/styles";
import { useTranslation } from "@coworker/locales";

interface SearchBarProps {
  articles: Articles;
  onSearchResults: (results: Articles) => void;
}

export const SearchBar = ({ articles, onSearchResults }: SearchBarProps) => {
  const [searchInput, setSearchInput] = useState("");
  const { t } = useTranslation();

  const handleSearch = (value: string) => {
    setSearchInput(value);

    if (!articles) return;

    onSearchResults(
      articles.filter(
        (article) =>
          article.itemName.toLowerCase().includes(value.toLowerCase()) ||
          article.itemType.toLowerCase().includes(value.toLowerCase()) ||
          article.itemColor.toLowerCase().includes(value.toLowerCase()) ||
          article.productArticleId.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleSearch(e.target.value);
  };

  const handleClear = () => {
    handleSearch("");
  };

  return (
    <SearchBarContainer>
      <FixaSearch
        id="article-search"
        value={searchInput}
        onChange={handleChange}
        onClear={handleClear}
        placeholder={t("searchBasicString")}
      />
    </SearchBarContainer>
  );
};
