import React from "react";
import { LoadingLinear } from "@ingka/loading";
import "@ingka/loading/dist/style.css";

type FixaLoadingLinearProps = {
  loadingValue: number;
  loadingMax: number;
};

const FixaLoadingLinear: React.FC<FixaLoadingLinearProps> = (
  props: FixaLoadingLinearProps
) => {
  return <LoadingLinear {...props} />;
};

export default FixaLoadingLinear;
