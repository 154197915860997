import React from "react";
import Modal, {
  ModalHeader,
  Sheets,
  ModalBody,
  ModalFooter,
} from "@ingka/modal";
import Button from "@ingka/button";
import { Trans, useTranslation } from "@coworker/locales";
import { FilterListView } from "./FilterListView";
import { useStoreTeams } from "@coworker/app/src/core/hooks/useStoreTeams";
import { ActivityFilterType } from "../../../types/filterOptionsTypes";
import { DEFAULT_FILTER_VALUES } from "../../../constants/defaultFilterValues";
import {
  getGrouppedFilterOptions,
  GroupedFilterOptionsType,
} from "../../../constants/filterOptions";
import { Task } from "@coworker/types/lib/tasks/base";
import "./filter-list-view.css";
import trackerHelper from "@coworker/app/src/helpers/tracker";
import { LocationFilter } from "./LocationFilter";
import { hasCallTasksServiceFlag } from "@coworker/reusable";

interface ActivityFiltersPanelProps {
  isPanelOpen: boolean;
  setIsPanelOpen: (value: boolean) => void;
  filters: ActivityFilterType;
  setFilters: (value: ActivityFilterType) => void;
  allTasks: Task[];
}

export const ActivityFiltersPanel = ({
  isPanelOpen,
  setIsPanelOpen,
  filters,
  setFilters,
  allTasks,
}: ActivityFiltersPanelProps) => {
  const { t } = useTranslation();
  const teams = useStoreTeams();
  const [panelFilters, setPanelFilters] = React.useState<ActivityFilterType>(
    DEFAULT_FILTER_VALUES
  );

  React.useEffect(() => {
    setPanelFilters(filters);
  }, [filters]);

  const handleGenericFilterChange = (filterId: string, value: string) => {
    trackerHelper.trackActivityFilterPanelClick(filterId, value);

    setPanelFilters({
      ...panelFilters,
      [filterId]: value,
    });
  };

  const handleLocationFilterChange = (value: string) => {
    let newLocationsFilterSet = panelFilters.locations;

    if (!newLocationsFilterSet) {
      newLocationsFilterSet = [];
    }

    if (newLocationsFilterSet.includes(value)) {
      newLocationsFilterSet.splice(newLocationsFilterSet.indexOf(value), 1);
    } else {
      newLocationsFilterSet.push(value);
    }
    trackerHelper.trackActivityFilterPanelClick(
      "locations",
      newLocationsFilterSet
    );

    setPanelFilters({
      ...panelFilters,
      locations: newLocationsFilterSet,
    });
  };

  const getFilterListComponent = (
    titleKey: string,
    filterName: keyof GroupedFilterOptionsType
  ) => {
    return (
      <FilterListView
        open
        title={titleKey}
        list={getGrouppedFilterOptions(teams)[filterName].map((item) => ({
          id: item.id,
          title: "name" in item ? item.name : t(item.transKey || ""),
          value: item.id,
          control: "radiobutton",
          checked: item.id === panelFilters[filterName],
        }))}
        handleChange={(newValue) => {
          handleGenericFilterChange(filterName, newValue);
        }}
      />
    );
  };

  const callTasksService = hasCallTasksServiceFlag();

  return (
    <Modal
      visible={isPanelOpen}
      handleCloseBtn={() => {
        setPanelFilters(filters);
        setIsPanelOpen(false);
      }}
      className="filters-panel"
    >
      <Sheets
        alignment={"right"}
        preserveAlignment={false}
        size="small"
        fullSize={true}
        header={<ModalHeader title={t("refineString")} floating={false} />}
        footer={
          <ModalFooter>
            <Button
              text={<Trans>resetString</Trans>}
              type="secondary"
              onClick={() => {
                trackerHelper.trackActivityResetFilterButtonClick();
                setIsPanelOpen(false);
                setPanelFilters({ ...DEFAULT_FILTER_VALUES, locations: [] });
                setFilters({ ...DEFAULT_FILTER_VALUES, locations: [] });
              }}
            />
            <Button
              text={<Trans>applyString</Trans>}
              type="primary"
              onClick={() => {
                trackerHelper.trackActivityApplyFilterButtonClick();
                setIsPanelOpen(false);
                setFilters(panelFilters);
              }}
            />
          </ModalFooter>
        }
      >
        <ModalBody>
          {getFilterListComponent("assignedToTaskFilterString", "assignedTo")}
          {getFilterListComponent("createdByString", "createdBy")}
          {getFilterListComponent("task_typeString", "taskType")}
          {getFilterListComponent("periodString", "period")}
          {getFilterListComponent("sortByTitleString", "sortedBy")}

          {panelFilters.taskType === "roomSettings" ||
          callTasksService ? null : (
            <LocationFilter
              panelFilters={panelFilters}
              allTasks={allTasks}
              handleChange={handleLocationFilterChange}
            />
          )}
        </ModalBody>
      </Sheets>
    </Modal>
  );
};
