import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { TasksSection } from "./TasksSection";
import NotificationCounterLink from "./NotificationCounterLink";
import { Trans, useTranslation } from "@coworker/locales";
import { useEvents } from "../hooks/useEvents";
import { useStoreId } from "../core/auth/useLoggedInUser";
import { virtualStoreRegex } from "../helpers/filters";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";

const CardStack = styled.div`
  width: 100%;
  background-color: var(--white);
  margin-top: 10px;
`;

const sections = {
  root: [
    // {
    //   title: <Trans>notificationsString</Trans>,
    //   link: "/tasks/activity/notifications",
    //   filter: "notifications",
    // },
    {
      title: <Trans>createdByMeString</Trans>,
      link: "/tasks/activity/created_by_me",
      filter: "created_by_me",
    },
    {
      title: <Trans>inProgressInMyTeamString</Trans>,
      link: "/tasks/activity/tasks/in_progress",
      filter: "in_progress",
    },
    {
      title: <Trans>inProgressInMyStoreString</Trans>,
      link: "/tasks/activity/tasks/all_ongoing",
      filter: "all_ongoing",
    },
    {
      title: <Trans>scheduledCustomTasks</Trans>,
      link: "/tasks/activity/tasks/planned",
      filter: "planned",
    },
    {
      title: <Trans>completedByMyTeamString</Trans>,
      link: "/tasks/activity/tasks/completed",
      filter: "completed",
    },
    {
      title: <Trans>closedByMyTeamString</Trans>,
      link: "/tasks/activity/tasks/closed",
      filter: "closed",
    },
    {
      title: <Trans>allCompletedInStore</Trans>,
      link: "/tasks/activity/tasks/all_completed",
      filter: "all_completed",
    },
    {
      title: <Trans>allClosedInThisStore</Trans>,
      link: "/tasks/activity/tasks/all_closed",
      filter: "all_closed",
    },
  ],
  created_by_me: [
    {
      title: <Trans>teamOpenString</Trans>,
      link: "/tasks/activity/tasks/created_by_me_open",
      filter: "created_by_me_open",
    },
    {
      title: <Trans>inProgressString</Trans>,
      link: "/tasks/activity/tasks/created_by_me_in_progress",
      filter: "created_by_me_in_progress",
    },
    {
      title: <Trans>scheduledCustomTasks</Trans>,
      link: "/tasks/activity/tasks/created_by_me_planned",
      filter: "created_by_me_planned",
    },
    {
      title: <Trans>completd3String</Trans>,
      link: "/tasks/activity/tasks/created_by_me_completed",
      filter: "created_by_me_completed",
    },
    {
      title: <Trans>closedFirstUppercaseString</Trans>,
      link: "/tasks/activity/tasks/created_by_me_closed",
      filter: "created_by_me_closed",
    },
  ],
};

function showNotificationsForStore(store_id) {
  const showNotifications =
    store_id.split(virtualStoreRegex)[0] % 6 === 0 ? true : false;

  const notificationsSection = {
    title: <Trans>notificationsString</Trans>,
    link: "/tasks/activity/notifications",
    filter: "notifications",
  };

  const hasNotifications = sections.root.findIndex((section) => {
    return section.filter === "notifications";
  });

  if (showNotifications && hasNotifications < 0) {
    sections.root = [notificationsSection, ...sections.root];
  }
}

export function Activity() {
  const store_id = useStoreId();
  showNotificationsForStore(store_id);
  const { type } = useParams();
  const events = useEvents();
  const number = events && events.filter((a) => a.is_read === false).length;
  const chosenSections = React.useMemo(
    () => sections[type || "root"] || [],
    [type]
  );
  const { t } = useTranslation();

  const getActivityContent = () => {
    return chosenSections.map((section) =>
      section.filter === "notifications" ? (
        <CardStack key={section.link}>
          <NotificationCounterLink
            isNotificationCounter
            number={number}
            title={<Trans>notificationsString</Trans>}
            to={section.link}
            testId="activity-notifications"
          />
        </CardStack>
      ) : (
        <TasksSection key={section.link} {...section} />
      )
    );
  };

  return (
    <>
      {!type && getActivityContent()}
      {type && (
        <FullScreenPopup
          noPadding
          appBarConfig={{
            title:
              type === "notifications"
                ? t("notificationsString")
                : t("createdByMeString"),
          }}
        >
          {getActivityContent()}
        </FullScreenPopup>
      )}
    </>
  );
}
