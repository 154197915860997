import React, { useEffect, useState } from "react";
import { SearchBar } from "./SearchBar";
import {
  CenterContainer,
  FlexibleRowContainer,
  ListWrapper,
  RightContainer,
} from "../styles/styles";
import { ArticleListItem } from "./ArticleListItem";
import { Article, ArticleIdNbrArticles, Articles } from "../types/article";
import { Room } from "../types/room";
import { useTasksByRoom } from "../hooks/useTasksByRoom";
import {
  hasOngoingTask,
  isBackupForProduct,
  isMainArticle,
  selectArticleIdNbrArticles,
} from "./RoomArticles.helper";
import { useEDSData } from "../hooks/useEDSData";
import { useSalesStopData } from "../hooks/useSalesStopData";
import "../room-tabs.css";
import { getArticlesCountText, joinProductArticleIds } from "../tools";
import { useCountryId } from "../../../core/auth/useLoggedInUser";
import { Group, Groups } from "../types/groups";
import {
  FixaAccordion,
  FixaAccordionItem,
} from "@coworker/apprestructured/src/shared/wrappers/FixaAccordion/FixaAccordion";
import { useTranslation } from "@coworker/locales";
import { SelectArticles } from "./SelectArticles";

import SSRIcon from "@ingka/ssr-icon";
import DotsIcon from "@ingka/ssr-icon/paths/ellipses-horizontal";
import { NarrowButton } from "../../SkapaButton";

interface RoomArticlesProps {
  sortedAndFilteredArticles: Articles;
  room: Room;
  groups: Groups;
  isSelectArticlesMode: boolean;
  getSelectedArticleIds: (
    fetchSelectedArticleIdsFunction: () => ArticleIdNbrArticles[]
  ) => void;
  groupMenuClicked: (group: Group) => void;
  setSelectArticleMode: () => void;
  allArticlesInRoom: Articles;
}

interface ArticleListingProps {
  groupId: string;
}

export const RoomArticles = ({
  sortedAndFilteredArticles,
  room,
  groups,
  getSelectedArticleIds,
  isSelectArticlesMode,
  groupMenuClicked,
  setSelectArticleMode,
  allArticlesInRoom,
}: RoomArticlesProps) => {
  const { t } = useTranslation();
  const countryId = useCountryId();

  const [selectedArticleIdsNbrArticles, setSelectedArticleIdsNbrArticles] =
    useState<ArticleIdNbrArticles[]>([]);
  const [searchResult, setSearchResult] = useState<Articles>([]);
  const [totalArticles, setTotalArticles] = useState(0);

  const { taskLinks } = useTasksByRoom(room.id);

  const articleNumbers = joinProductArticleIds(sortedAndFilteredArticles);
  const { data: edsData } = useEDSData(countryId, articleNumbers);
  const { data: salesStopData } = useSalesStopData(
    room.store_id,
    articleNumbers
  );

  useEffect(() => {
    if (sortedAndFilteredArticles) {
      const aggregatedArticles = sortedAndFilteredArticles.reduce(
        (acc, cur) => {
          return acc + cur.nbrArticles;
        },
        0
      );
      setTotalArticles(aggregatedArticles);
      setSearchResult(sortedAndFilteredArticles);
    }
  }, [sortedAndFilteredArticles]);

  useEffect(() => {
    if (!isSelectArticlesMode) setSelectedArticleIdsNbrArticles([]);
  }, [isSelectArticlesMode]);

  getSelectedArticleIds(() => {
    return selectedArticleIdsNbrArticles;
  });

  const ArticleListing = ({ groupId }: ArticleListingProps) => {
    return (
      <ListWrapper>
        {searchResult &&
          searchResult
            .filter((article) => article.groupId === groupId)
            .map((article) => (
              <ArticleListItem
                key={article.id}
                article={article}
                isMainProduct={() =>
                  isMainArticle(
                    article.id,
                    room.main_article_id_1,
                    room.main_article_id_2
                  )
                }
                isBackupForProduct={() =>
                  isBackupForProduct(
                    article.id,
                    room.backup_article_id_1 ?? "",
                    room.backup_article_id_2 ?? ""
                  )
                }
                hasOngoingTask={() => hasOngoingTask(article.id, taskLinks)}
                edsData={
                  edsData?.find(
                    (eds: { productArticleId: string }) =>
                      eds.productArticleId === article.productArticleId
                  ) ?? null
                }
                salesStopData={
                  salesStopData?.find(
                    (salesStop) =>
                      salesStop.productArticleId === article.productArticleId
                  ) ?? null
                }
                selectArticleIdsMode={isSelectArticlesMode}
                onSelectArticleIdNbrArticles={(articleIdNbrArticles) =>
                  selectArticleIdNbrArticles(
                    articleIdNbrArticles,
                    selectedArticleIdsNbrArticles,
                    setSelectedArticleIdsNbrArticles
                  )
                }
                isSelected={
                  !!selectedArticleIdsNbrArticles.find(
                    (item) => item.articleId === article.id
                  )
                }
                showActionRow={true}
                onArticleUpdate={(newArticle: Article, isSkeleton: boolean) => {
                  const otherArticles = searchResult.filter(
                    (a) => a.id !== newArticle.id
                  );
                  otherArticles.push({ ...newArticle, isSkeleton: isSkeleton });
                  setSearchResult(otherArticles);
                }}
              />
            ))}
      </ListWrapper>
    );
  };

  const isSearch = sortedAndFilteredArticles.length !== searchResult.length;
  const isFilter =
    allArticlesInRoom.length !== sortedAndFilteredArticles.length;
  return (
    <>
      <SearchBar
        articles={sortedAndFilteredArticles}
        onSearchResults={(result: Articles) => setSearchResult(result)}
      />
      {searchResult.length > 0 && (
        <NarrowButton
          type={"secondary"}
          text={
            isSelectArticlesMode ? t("cancelString") : t("selectArticlesString")
          }
          onClick={setSelectArticleMode}
          fluid
        />
      )}
      <SelectArticles
        selectedCount={selectedArticleIdsNbrArticles.length}
        piecesCount={totalArticles}
        searchCount={searchResult.length}
        isSelected={isSelectArticlesMode}
        onSelectAll={(selectAllChecked: boolean) => {
          if (selectAllChecked) {
            setSelectedArticleIdsNbrArticles(
              sortedAndFilteredArticles.map((article) => ({
                articleId: article.id,
                nbrArticles: article.nbrArticles,
              }))
            );
          } else {
            setSelectedArticleIdsNbrArticles([]);
          }
        }}
        allArticlesInRoom={allArticlesInRoom}
      />
      <ArticleListing groupId="" />
      <FixaAccordion collapsible={false}>
        {groups.map((group) => {
          const articlesInGroup = searchResult.filter(
            (article) => article.groupId === group.id
          );

          const pieces = articlesInGroup.reduce(
            (acc, cur) => acc + cur.nbrArticles,
            0
          );
          const nbrArticles = articlesInGroup.length;
          const selectedCount = articlesInGroup.filter((filteredArticle) =>
            selectedArticleIdsNbrArticles.some(
              (idNbr) => idNbr.articleId === filteredArticle.id
            )
          ).length;
          const searchCount = articlesInGroup.filter((filteredArticle) =>
            searchResult.some(
              (searchedArticle) => filteredArticle.id === searchedArticle.id
            )
          ).length;

          let showGroup = true;
          if (isSelectArticlesMode) showGroup = articlesInGroup.length > 0;
          else if (isSearch || isFilter) showGroup = searchCount > 0;

          if (showGroup) {
            return (
              <FixaAccordionItem
                key={group.id}
                id={`acc-item-${group.id}`}
                subtle={true}
                title={
                  <div style={{ marginLeft: "24px" }}>
                    <FlexibleRowContainer>
                      <CenterContainer $centerText={false}>
                        <b>{group.name}</b>
                        {getArticlesCountText(
                          t,
                          isSelectArticlesMode,
                          isSearch || isFilter,
                          selectedCount,
                          nbrArticles,
                          searchCount,
                          pieces
                        )}
                      </CenterContainer>
                      <RightContainer
                        onClick={() => {
                          groupMenuClicked(group);
                        }}
                      >
                        <SSRIcon paths={DotsIcon} />
                      </RightContainer>
                    </FlexibleRowContainer>
                  </div>
                }
              >
                <ArticleListing groupId={group.id} />
              </FixaAccordionItem>
            );
          }
          return <></>;
        })}
      </FixaAccordion>
    </>
  );
};
