import { useMutation, useQueryClient } from "@tanstack/react-query";
import { PartialRoom } from "../types/room";
import { updateRoom } from "../services/rooms.service";
import { QueryKeys } from "./queryKeys";

export const useUpdateRoom = (roomId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: PartialRoom) => updateRoom(roomId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.Room, roomId],
      });
    },
  });
};
