import React from "react";
import { RoomsViewMobile } from "./RoomsViewMobile";
import { useWindowProperties } from "../../Scanning/useWindowProperties";
import { FixaWindow } from "@coworker/reusable";
import { RoomsViewDesktop } from "./RoomsViewDesktop";
import { ViewportSize } from "@coworker/apprestructured/src/shared/constants/viewportSizes";

export function RoomsView() {
  const {
    size: { width },
  } = useWindowProperties();

  if (
    (window as FixaWindow).editableRoomSettingsSettings &&
    width > ViewportSize.DESKTOP
  ) {
    return <RoomsViewDesktop />;
  } else {
    return <RoomsViewMobile />;
  }
}
