import React, { useEffect, useState } from "react";
import { Room } from "../../types/room";
import { useRoomsByStoreId } from "../../hooks/useRoomsByStoreId";
import { useRoomById } from "../../hooks/useRoomById";
import Modal, { ModalFooter, ModalHeader, Sheets } from "@ingka/modal";
import Button from "@ingka/button";
import { useUpdateRoom } from "../../hooks/useUpdateRoom";
import { useTranslation } from "@coworker/locales";
import {
  checkEmptyRoomName,
  checkRoomName,
  EmptyRoom,
} from "../SettingsForm.helper";
import { MessageModal } from "./MessageModal";
import { OkCancelModal } from "./OkCancelModal";
import { SettingsForm } from "../SettingsForm";
import { useArticlesByRoomId } from "../../hooks/useArticlesByRoomId";
import { DeleteRoomModal } from "./DeleteRoomModal";

interface UpdateSettingsModalProps {
  roomId: string;
  isVisible: boolean;
  onClose: () => void;
}

export const UpdateSettingsModal = function ({
  roomId,
  isVisible,
  onClose,
}: UpdateSettingsModalProps) {
  const { t } = useTranslation();
  const { data: roomData } = useRoomById(roomId);
  const updateRoomMutation = useUpdateRoom(roomId);
  const [showModalMessage, setShowModalMessage] = useState("");
  const [showConfirmCloseModal, setShowConfirmCloseModal] = useState(false);
  const [roomIsUpdated, setRoomIsUpdated] = useState(false);
  const [room, setRoom] = useState(EmptyRoom);
  const { data: rooms } = useRoomsByStoreId(room.store_id);
  const { data: articles } = useArticlesByRoomId(roomId);

  useEffect(() => {
    if (roomData) {
      setRoom(roomData);
    }
  }, [roomData]);

  const onSubmit = async () => {
    try {
      await updateRoomMutation.mutateAsync(room);
      onClose();
    } catch (error) {
      console.log(error);
      setShowModalMessage(t("couldNotUpdateRoomTryAgainString"));
    }
  };

  const onCloseWarning = () => {
    if (roomIsUpdated) {
      setShowConfirmCloseModal(true);
    } else {
      onClose();
    }
  };

  const saveRoomChanges = (saveRoom: Room) => {
    setRoomIsUpdated(true);
    setRoom(saveRoom);
  };

  if (!room) {
    return <></>;
  }

  return (
    <>
      <Modal visible={isVisible} handleCloseBtn={onCloseWarning}>
        <Sheets
          labelledById="edit-room-setting"
          header={<ModalHeader title={t("editRoomSettingsString")} />}
          footer={
            <ModalFooter>
              <Button
                type="primary"
                onClick={onSubmit}
                disabled={
                  !rooms ||
                  checkEmptyRoomName(room) ||
                  checkRoomName(room, rooms) ||
                  !roomIsUpdated
                }
                aria-label={t("saveString")}
              >
                {t("saveString")}
              </Button>
            </ModalFooter>
          }
        >
          <SettingsForm
            room={room}
            articles={articles ?? []}
            onSave={saveRoomChanges}
          />
          <DeleteRoomModal room={room} />
        </Sheets>
      </Modal>
      {!!showModalMessage && (
        <MessageModal
          message={showModalMessage}
          closeModal={() => {
            setShowModalMessage("");
          }}
        />
      )}
      {showConfirmCloseModal && (
        <OkCancelModal
          onOk={() => {
            setShowConfirmCloseModal(false);
            onClose();
            setRoom(roomData ?? EmptyRoom);
          }}
          onCancel={() => {
            setShowConfirmCloseModal(false);
          }}
          title={t("confirmCloseString")}
          text={t("allChangesLostCloseString")}
        />
      )}
    </>
  );
};
