import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { InsightProductItem } from "./InsightProductItem";
import InsightsLocationItem from "./LocationItem";
import { Content } from "./styles";
import styled from "styled-components";
import { useTranslation } from "@coworker/locales";
import Filters from "./Filters";
import { useInsightsFetchParams } from "./helpers";
import GraphCardWrapper from "./GraphCardWrapper";
import AddonProducts from "./AddonProducts";
import AddonLocations from "./AddonLocations";
import LocationContribution from "./LocationContribution";
import ProductLocationDetails from "./ProductLocationDetails";
import { parseProduct } from "../../services/products.service";
import { useWorkspacesAction } from "../../hooks/useWorkspaces";
import {
  splitLocation,
  useLocationCapacity,
} from "../../services/locations.service";
import { Button, useToastNotification } from "@coworker/components";
import { useCallInsightsFunction } from "../../hooks/API/useCallFirebaseFunction";
import { INSIGHTS_HOME_PAGE } from "@coworker/functions/src/enums/insightsRequestTypes";
import { ReactComponent as EditIcon } from "../../assets/svg/pencil.svg";
import { ReactComponent as CheckmarkIcon } from "../../assets/svg/checkmark.svg";
import tracker from "../../helpers/tracker";
import { useUrlParams } from "@coworker/common/hooks/useUrlParams";
import { useInsightsEditStatus } from "../../features/insights/useInsightsEditStatus";
import { AddonsWithPagination } from "./AddonWithPagination";
import LivligLinkBox from "./LivligLinkBox";
import { isRDTDevice } from "@coworker/reusable";
import { StoreChange } from "./StoreChange";
import { useStoreId } from "../../core/auth/useLoggedInUser";
import trackerHelper from "../../helpers/tracker";
import { useStoreById } from "../../hooks/useMyStore";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";

const DRILL_PRODUCT = "product";
const DRILL_LOCATION = "location";

function itemDrillDownLinker(pathComponentName, { product_id, location }) {
  if (product_id) {
    return (id) =>
      `/insights/product/${product_id}/location/${encodeURIComponent(id)}`;
  }
  if (location) {
    return (id) =>
      `/insights/product/${id}/location/${encodeURIComponent(location)}`;
  }
  return (id) => `/insights/${pathComponentName}/${encodeURIComponent(id)}`;
}

const HeadingFollowerContainer = styled.div`
  position: sticky;
  top: 57px;
  width: 100%;
  z-index: var(--z-heading-follower);
`;

const STORE_CHOICE_KEY = "insights_store_choice";

function Details({ scrollDirection, isOnline }) {
  const { showToast } = useToastNotification();
  const { getEditStatus, setEditStatus } = useInsightsEditStatus();
  const editEnabled = getEditStatus();
  const { location_id: locationId, product_id: productId } = useParams();
  const { push } = useWorkspacesAction();
  const [shortId, type] = parseProduct(productId);
  const [gridcode] = splitLocation(locationId);
  const locationMax = useLocationCapacity(gridcode, `${type}${shortId}`);
  const urlParams = useUrlParams();
  const searchedFromExcel = urlParams.get("from") === "excel";
  if (urlParams.get("store")) {
    sessionStorage.setItem(STORE_CHOICE_KEY, urlParams.get("store"));
  }
  const [refetchData, setRefetchData] = React.useState(false);
  const isRDT = isRDTDevice();
  const myStoreId = useStoreId();
  const [currentStore, setCurrentStore] = React.useState({
    id: sessionStorage.getItem(STORE_CHOICE_KEY) || myStoreId,
  });
  const onStoreChange = React.useCallback((choice) => {
    console.log("chocie", choice);
    setCurrentStore(choice);
    sessionStorage.setItem(STORE_CHOICE_KEY, choice?.id || "");
  }, []);
  const isShowingMyStore = myStoreId === currentStore?.id;

  const { data: fetchedStore, loading } = useStoreById(currentStore?.id);

  const { t } = useTranslation();

  React.useEffect(() => {
    if (fetchedStore && !loading) setCurrentStore(fetchedStore?.data?.data);
  }, [loading, fetchedStore]);

  const [dataFetchParams, onChangeInsightsParams, isFetchingSavedFilters] =
    useInsightsFetchParams({
      store_id: currentStore?.id,
      location_id: locationId,
      product_id: productId,
    });

  // COAPP-5656: When entering from Excel we want to show all HFBs (for now) and BOTH Home and Away.
  const hfb = dataFetchParams?.hfb; // department === "ALL" ? null : department;
  // This page reloads and removes all url parameters (including store), so the ?from=excel is also lost.
  // Therefore we must for now overwrite the user profile
  // configuration for the HFB and Home/Away filter
  const default_hfb_request_type = dataFetchParams?.hfb_request_type; // savedInsightsFilter?.deptFilters?.salesShare;

  React.useEffect(() => {
    if (searchedFromExcel) {
      const updatedInsightsFilter = {};
      {
        const deptFilters = {};
        if (hfb) {
          updatedInsightsFilter.deptFilters = deptFilters;
          deptFilters.hfb = "ALL";
        }
        if (default_hfb_request_type) {
          updatedInsightsFilter.deptFilters = deptFilters;
          deptFilters.salesShare = "";
        }
      }
      onChangeInsightsParams(updatedInsightsFilter);
    }
  }, [
    searchedFromExcel,
    hfb,
    default_hfb_request_type,
    onChangeInsightsParams,
  ]);

  const [isLocation, isProduct] = [locationId, productId];

  // This data is preloaded and only used to send it in to other components.
  // Maybe only used for showing use in the filters, and for the Insights correction -- why?
  const activeHFB = useCallInsightsFunction(INSIGHTS_HOME_PAGE, {
    ...dataFetchParams,
    isFetchingSavedFilters,
  });

  if (isProduct && !isLocation && searchedFromExcel) {
    tracker.insights.trackProductSearch("excel");
  }

  // We want to refetch data for Locations when refill quantity is edited
  React.useEffect(() => {
    if (!editEnabled) {
      setRefetchData(false);
      setTimeout(() => setRefetchData(true), 1000);
    }
  }, [editEnabled]);

  const appBarAction = [];

  if (isProduct && !isLocation) {
    appBarAction.push({
      name: "Edit",
      icon: editEnabled ? <CheckmarkIcon /> : <EditIcon />,
      onClick: () => {
        if (!isShowingMyStore) {
          showToast(t("cantChangeStoreString"));
        } else {
          if (!editEnabled) {
            trackerHelper.insights.corrections.enableCorrections();
          }
          setEditStatus(!editEnabled);
        }
      },
      position: "right",
    });
  }

  const onUnmountRef = useRef(() => setEditStatus(false));
  const initialOnUnMount = onUnmountRef.current;

  useEffect(() => {
    return () => {
      initialOnUnMount();
    };
  }, [initialOnUnMount]);

  return (
    <FullScreenPopup
      noPadding
      appBarConfig={{
        title: t("addonInsightsString"),
        actions: appBarAction,
      }}
      actionBarContent={
        locationId ? (
          <Button
            onClick={() =>
              push("/task/new/addon", {
                product: {
                  product: shortId,
                  type,
                },
                location: locationId, // TODO: Remove this line when MultipleAddonsFlow gets removed.
                location_id: locationId,
                location_max: locationMax,
                storeId: currentStore?.id,
              })
            }
            text={t("requestRefillString")}
            primary
            dark
            fullWidth
            disabled={!isOnline || !isShowingMyStore}
            data-testid="requestRefillButton"
          />
        ) : null
      }
    >
      <StoreChange
        hideButton={editEnabled}
        store={currentStore}
        onStoreChange={onStoreChange}
      />
      <HeadingFollowerContainer>
        <Filters
          scrollDirection={scrollDirection}
          onChangeInsightsParams={onChangeInsightsParams}
          dataFetchParams={dataFetchParams}
          activeHFB={activeHFB}
          level={isProduct && isLocation ? 4 : 3}
        />
      </HeadingFollowerContainer>
      {isProduct && <InsightProductItem fullId={productId} />}
      {isProduct && !isRDT && (
        <LivligLinkBox storeId={currentStore?.id} shortId={shortId} />
      )}
      {isLocation && (
        <InsightsLocationItem location={splitLocation(locationId)} />
      )}
      <Content noPadding>
        {!editEnabled && (
          <GraphCardWrapper
            dataFetchParams={{ ...dataFetchParams, isFetchingSavedFilters }}
            onChangeInsightsParams={onChangeInsightsParams}
          />
        )}
        {isLocation && isProduct && (
          <LocationContribution
            params={{ ...dataFetchParams, isFetchingSavedFilters }}
          />
        )}

        {!isProduct && !editEnabled && (
          <AddonProducts
            params={{ ...dataFetchParams, isFetchingSavedFilters }}
            itemLinker={itemDrillDownLinker(DRILL_PRODUCT, {
              product_id: productId,
              location: locationId,
            })}
          />
        )}
        {!isLocation && refetchData && !editEnabled && (
          <AddonLocations
            path="product"
            params={{ ...dataFetchParams, isFetchingSavedFilters }}
            itemLinker={itemDrillDownLinker(DRILL_LOCATION, {
              product_id: productId,
              location: locationId,
            })}
          />
        )}
        {isProduct && editEnabled && (
          <AddonsWithPagination
            path="location"
            dataFetchParams={{ ...dataFetchParams, isFetchingSavedFilters }}
            headless
            activeHFB={activeHFB}
          />
        )}
      </Content>

      {isLocation && isProduct && (
        <ProductLocationDetails
          params={{ ...dataFetchParams, isFetchingSavedFilters }}
        />
      )}
    </FullScreenPopup>
  );
}

export const InsightsLocationDetails = (props) => {
  return <Details {...props} />;
};

export const InsightsProductDetails = (props) => {
  return <Details {...props} />;
};

export const InsightsProductLocationDetails = (props) => {
  return <Details {...props} />;
};
