import React, { useState, useRef } from "react";
import TextArea from "@ingka/text-area";
import styled from "styled-components";
import SkapaButton from "@ingka/button";
import { ReactComponent as CancelIcon } from "@coworker/components/src/assets/svg/icon-close-large.svg";
import { addArticlesBulk } from "../../components/RoomSettings/services/articles.service";
import { fetchArticlesInfo } from "../../hooks/API/tasks.service.helper";
import statusTypes from "@coworker/functions/src/enums/statusTypes.json";
import {
  Article,
  ArticleInfo,
  ArticlesInfo,
} from "../../components/RoomSettings/types/article";
import { useQueryClient } from "@tanstack/react-query";
import { useInputPopup } from "../InputPopup";
import { SkapaToast } from "../RoomSettings/Atoms/SkapaToast";
import { mapArticleInfoToArticle } from "../../components/RoomSettings/types/mappers";
import { LoaderIcon } from "@coworker/reusable/Loader";
import { useDelayedValue, useDebouncedValue } from "../../hooks/useDebounce";
import {
  useWorkspacesAction,
  useWorkspacesState,
} from "../../hooks/useWorkspaces";
import useAutocompleteProducts from "../../hooks/useAutocompleteProducts";
import { useMixedSearchProducts } from "../../hooks/useSearchProducts";
import { SearchSuggestion, Button, SearchResult } from "@coworker/components";
import { parseProduct } from "../../services/products.service";
import { formatProductIdWithDots } from "@coworker/reusable/formatters/product.formatter";
import { Trans, useTranslation } from "@coworker/locales";
import { SearchBarContainer } from "../../components/RoomSettings/styles/styles";
import FixaSearch from "@coworker/apprestructured/src/shared/wrappers/FixaSearch/FixaSearch";
import { correctArticleNumberLength } from "@coworker/functions/src/testbuy/acesAndKing/4A1K.helper";

const RoomSettingsPopupContainer = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  overflow: auto;
`;
const RoomSettingsPopupTitle = styled.h1`
  display: flex;
  justify-content: flex-end;
`;
const HeadingWrapper = styled.h1`
  font-size: 16px;
  font-weight: bold;
  color: var(--grey900);
  padding: 25px;
  margin: 0 auto;
`;
const HeadingAndButtonWrapper = styled.div`
  display: inline-flex;
`;
const PaddedHorizontalContainer = styled.div`
  width: 312px;
  padding: 0 24px;
  z-index: 1;
`;

const ModalItemWrapper = styled.div`
  > * + * {
    margin-top: 20px;
  }
  margin-bottom: 90px;
`;
const CancelButton = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  margin-right: 7px;
  margin-left: 23px;
  margin-top: 15px;
  background: none;
`;

const CloseIcon = styled(CancelIcon)`
  color: var(--grey900);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const AnimatedLoader = styled(LoaderIcon)`
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  left: 35%;
  z-index: 1;
`;

const Container = styled.div<{ changeZIndex?: number }>`
  background: var(--white);
  width: 100%;
  top: 100px;
  left: 0;
  ${(props) =>
    props.changeZIndex ? `z-index: ${props.changeZIndex};` : `z-index: 3;`}
`;

const SearchResultsContainer = styled.div`
  min-height: calc(100% - 71px);
  display: flex;
  flex-direction: column;
`;

const Loader = styled(LoaderIcon)`
  width: 30px;
  margin: 20px auto;
  display: block;
`;

const NoResultsContainer = styled.div`
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--grey700);
  padding: 20px 29px 9px 29px;
`;

const SPRArticlesExcludedWarning = styled.div`
  padding-top: 10px;
`;

const ResultWrap = styled.div``;

const ResultWrapText = styled.div`
  font-size: 12px;
  padding: 0 10px;
`;

const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  font-size: 14px;
  text-align: center;
  padding: 20px 25px 20px 25px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const LoadMoreButton = styled(Button)`
  margin: 10px auto;
`;

const ErrorContainer = styled(LoadingContainer)`
  justify-content: flex-end;
  *:first-child {
    padding-bottom: 100%;
  }
`;

const SlowLoadingContainer = styled.div`
  margin: auto;
`;

const TryAgainContainer = styled.div`
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  padding: 20px 25px 20px 25px;
  display: flex;
  flex-direction: column;

  @media (min-width: 1440px) {
    padding-bottom: 128px;
  }
`;

const InformationBox = styled.div`
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--grey700);
  padding: 13px 2px 2px 9px;
`;

const InformationBoxFirstLine = styled.div`
  font-size: 14px;
  letter-spacing: normal;
  color: var(--grey700);
  line-height: 1.5;
  margin-left: 15px;
`;

const InformationBoxSecondLine = styled.div`
  font-size: 14px;
  letter-spacing: normal;
  color: var(--grey700);
  margin-left: 15px;
  margin-bottom: 7px;
`;

const Seperator = styled.div`
  border-top: 2px solid var(--grey150);
  width: 140px;
`;

const SeparatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonWrapperFixed = styled.div`
  position: fixed;
  bottom: 0;
  width: 360px;
  background: white;
  border-top: 1px solid var(--grey150);
  align-items: center;
  padding: 24px;
  z-index: 2;
`;

const ButtonWrapperInner = styled.div`
  width: 312px;
`;

const SearchContainer = styled(SearchBarContainer)`
  padding: 0 24px 15px 24px;
`;
interface AddMultipleArticlesType {
  isOpen: boolean;
  roomId: string;
  groupId: string;
  countryId: string;
}

export function AddMultipleArticles(props: AddMultipleArticlesType) {
  const [inputValue, setInputValue] = useState<string>("");
  const queryClient = useQueryClient();
  const [toastVisible, setToastVisible] = React.useState(false);
  const [toastText, setToastText] = React.useState("");
  const [isAddingArticles, setIsAddingArticles] = React.useState(false);
  const { t } = useTranslation();
  const { closePopup } = useInputPopup();
  const [modalZIndex, setModalZIndex] = useState(3);
  const [, setFocus] = useState(true);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value);

    const lines = e.target.value.split("\n");

    const cleanedLines = lines.map((line) => {
      const cleanedLine = line.replace(/[^0-9\s]/g, "").replace(/\t/g, " ");
      return cleanedLine.includes(" ") ? cleanedLine : cleanedLine.slice(0, 8);
    });

    const cleanedInput = cleanedLines.join("\n");
    if (cleanedInput.replace(/\D/g, "").length >= 8) {
      setInputValue(cleanedInput);
    }

    const addedArticles = cleanedInput.split("\n");
    addedArticles.reverse();
  };

  const handleSubmit = async () => {
    setIsAddingArticles(true);
    const articles =
      typeof inputValue === "string" ? inputValue.split("\n") : inputValue;
    const articleCountMap: { [id: string]: number } = {};
    const articleIdsWithQuantity: { articleId: string; quantity: number }[] =
      [];
    articles.forEach((article) => {
      const [id, quantity] = article.split(" ");
      if (id && articleCountMap[id]) {
        articleCountMap[id] += quantity ? parseInt(quantity, 10) : 1;
      } else if (id) {
        articleCountMap[id] = quantity ? parseInt(quantity, 10) : 1;
      }
    });

    Object.keys(articleCountMap).forEach((key) => {
      if (key !== "") {
        const quantity = articleCountMap[key];
        if (quantity !== undefined) {
          articleIdsWithQuantity.push({
            articleId: key,
            quantity,
          });
        }
      }
    });

    if (articleIdsWithQuantity.length > 0) {
      const articleIds = articleIdsWithQuantity.map(
        (article: { articleId: string; quantity: number }) =>
          correctArticleNumberLength(article.articleId.trim())
      );

      const articleInfoList: ArticlesInfo = await fetchArticlesInfo(
        articleIds.join(",")
      );
      // Filter out articles which do not have article data to add them as unknown articles
      const articleInfosNotAdded = articleIds
        .filter(
          (articleId) =>
            !articleInfoList
              .map((articleData) => articleData.id)
              .filter((id) => id)
              .includes(articleId)
        )
        .map((articleId) => {
          return { id: articleId } as ArticleInfo;
        });

      articleInfoList.push(...articleInfosNotAdded);

      if (articleInfoList.length > 0) {
        try {
          await addArticlesBulk(
            articleInfoList.map((articleData) => {
              const article = articleIdsWithQuantity.find(
                (articleIdAndQuantity) =>
                  articleIdAndQuantity.articleId === articleData.id
              );

              return {
                ...mapArticleInfoToArticle(articleData),
                active: statusTypes.ACTIVE,
                nbrArticles: article && article.quantity ? article.quantity : 1,
                roomId: props.roomId,
              } as Article;
            }),
            props.groupId
          );
          await queryClient.invalidateQueries([
            "articlesByRoom",
            { id: props.roomId },
          ]);
        } catch (error) {
          alert(t("searchErrorString"));
        }
      } else {
        setToastText(t("noDataMessage"));
        setToastVisible(true);
      }
    }
    setIsAddingArticles(false);
    closePopup();
  };

  const onClose = () => {
    closePopup();
  };

  const changeZindex = () => {
    return { changeZIndex: modalZIndex };
  };

  function matchesStringCaseInsesitive(p: string, query: string) {
    return p?.toLowerCase() === query?.toLowerCase();
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLTextAreaElement>) {
    const key = event.key;
    // Check if Ctrl or Cmd is pressed along with 'v'
    const isPaste =
      (event.ctrlKey || event.metaKey) && key.toLowerCase() === "v";
    const isAllowedKey = [
      "ArrowUp",
      "ArrowDown",
      "ArrowLeft",
      "ArrowRight",
      "Enter",
      "Backspace",
    ].includes(key);
    // Leaving the Enter and Backspace keys to work as default and characters from a to z except 'v' to be prevented since we want to allow paste.
    if (key.match(/[a-tu-z]/i) && !isPaste && !isAllowedKey) {
      // Prevent default action if the key is a character key
      event.preventDefault();
    }
  }

  interface SearchProps {
    hideSPR: boolean;
    withHistory: boolean;
  }

  const SearchInput = ({ hideSPR, withHistory }: SearchProps) => {
    const { setNavigationState } = useWorkspacesAction();
    const { navigationState } = useWorkspacesState();
    const [query, setQuery] = React.useState("");
    const debouncedQuery = useDebouncedValue(query, 100);

    const {
      data: proposals,
      loading: loadingSuggestions,
      dataIsFromAPI: finishedLoadingSuggestions,
      error: autocompleteError,
    }: any = useAutocompleteProducts(query, {
      blockRequest: !query,
      cacheTimeout: 0, // Add the missing cacheTimeout property
    });

    const {
      data,
      dupesMFS,
      dupesSIK,
      hiddenMFS,
      hiddenSIK,
      totalMFS,
      totalSIK,
      RESULT_LIMIT_PER_PAGE,
      error: searchProductsError,
      page,
      loading,
      nextPage,
    } = useMixedSearchProducts(debouncedQuery, {
      proposals,
      finishedLoadingSuggestions,
      hideSPR,
      blockRequest: !debouncedQuery,
      includeNumberSearch: true, // Here we should include product number search to cover all food products etc that aren't included in either of SIK/MFS search results.
    });

    const showDelayedWarning = useDelayedValue(loading && query, 4000);

    const update = (
      string: string,
      focus: boolean | ((prevState: boolean) => boolean)
    ) => {
      setQuery(string);
      setFocus(focus);
      if (textAreaRef.current) {
        textAreaRef.current.focus();
      }
    };

    React.useEffect(() => {
      if (
        withHistory &&
        navigationState &&
        navigationState.query !== query &&
        query &&
        textAreaRef.current
      ) {
        setNavigationState({ query });
      }
    }, [withHistory, query, navigationState, setNavigationState]);

    React.useEffect(() => {
      if (navigationState && navigationState.query && withHistory) {
        update(navigationState.query, false);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const items = data.slice(0, page * RESULT_LIMIT_PER_PAGE);
    const amountMore =
      totalMFS -
      dupesMFS -
      hiddenMFS +
      (totalSIK - dupesSIK - hiddenSIK) -
      items.length;
    return (
      <Container data-testid="searchContainer" onClick={() => changeZindex()}>
        <SearchContainer>
          <FixaSearch
            id="search-articles-room-settings"
            value={query}
            onChange={(e) => update(e.target.value, true)}
            onClear={() => {
              setNavigationState({ query: "" });
              update("", true);
            }}
            placeholder={t("searchBasicString")}
          />
        </SearchContainer>
        <SearchResultsContainer data-testid="searchResults">
          {!autocompleteError && !searchProductsError && loadingSuggestions && (
            <Loader />
          )}
          {!loadingSuggestions && query && (
            <div data-testid="autocomplete-suggestions">
              {!searchProductsError &&
                proposals
                  .filter((p: string) => !matchesStringCaseInsesitive(p, query))
                  .map((p: React.Key | null | undefined) => (
                    <SearchSuggestion
                      clickable
                      key={p}
                      onClick={() => {
                        update(p?.toString() || "", true);
                        return null;
                      }}
                      text={p?.toString() || ""}
                      uppercase
                    />
                  ))}
            </div>
          )}
          {!loading &&
            query.length > 0 &&
            debouncedQuery &&
            !loadingSuggestions &&
            items.length === 0 && (
              <NoResultsContainer>
                <div>{t("noResultsForString", { query })}</div>
                <SPRArticlesExcludedWarning>
                  {hideSPR ? t("sprArticlesExcluded") : ""}
                </SPRArticlesExcludedWarning>
              </NoResultsContainer>
            )}
          {(!loading || page > 1) && query && (
            <ResultWrap data-testid="searchResultWrap">
              {items &&
                items.map(
                  (
                    item: {
                      key: string | undefined;
                      title: string;
                      kindAndColor: string;
                      descriptivesWithoutKindAndColor: string;
                      measurements: number;
                      image: string;
                    },
                    index: number
                  ) => {
                    const [short_id] = parseProduct(item.key);
                    return (
                      <SearchResult
                        testId={`searchResult${index}`}
                        key={short_id}
                        title={item.title}
                        kindAndColor={item.kindAndColor}
                        descriptivesWithoutKindAndColor={
                          item.descriptivesWithoutKindAndColor
                        }
                        formattedNumber={formatProductIdWithDots(short_id)}
                        image={item.image}
                        onClick={() => {
                          setModalZIndex(1);
                          update("", true);
                          const addToInput = Array.isArray(inputValue)
                            ? inputValue
                            : inputValue.split("\n");
                          addToInput.push(short_id);
                          setInputValue(
                            addToInput
                              .filter((value) => value !== "")
                              .join("\n")
                          );
                          setQuery("");
                          setNavigationState({ query: "" });
                          setFocus(true);
                        }}
                        className={undefined}
                      />
                    );
                  }
                )}
            </ResultWrap>
          )}
          {(autocompleteError || searchProductsError) && (
            <ErrorContainer>
              <Trans>searchErrorString</Trans>
              <TryAgainContainer
                onClick={() => {
                  setQuery("");
                }}
              >
                <Trans>tryAgainString</Trans>
              </TryAgainContainer>
            </ErrorContainer>
          )}
          {loading && (
            <LoadingContainer>
              <Loader />
              {!!showDelayedWarning && (
                <SlowLoadingContainer>
                  <Trans>slowLoadingInfoString</Trans>
                </SlowLoadingContainer>
              )}
            </LoadingContainer>
          )}
          {!loading && amountMore > 0 && query && (
            <LoadMoreButton
              customMargin
              data-testid="searchResultMoreButton"
              dark={true}
              primary={true}
              onClick={() => nextPage()}
              text={t("amountMoreString", { count: amountMore })}
            />
          )}
        </SearchResultsContainer>
      </Container>
    );
  };

  return (
    <>
      <RoomSettingsPopupContainer>
        <HeadingAndButtonWrapper>
          <HeadingWrapper>
            <Trans>addArticlesString</Trans>
          </HeadingWrapper>
          <RoomSettingsPopupTitle>
            <CancelButton
              onClick={onClose}
              data-testid="closeAddmultipleArticles"
            >
              <CloseIcon />
            </CancelButton>
          </RoomSettingsPopupTitle>
        </HeadingAndButtonWrapper>
        <SearchInput hideSPR={true} withHistory={true} />
        <PaddedHorizontalContainer>
          <SeparatorWrapper>
            <Seperator />
            <ResultWrapText>Or</ResultWrapText>
            <Seperator />
          </SeparatorWrapper>
          <InformationBox>
            <Trans>scanArticleRDTString</Trans>
          </InformationBox>
          <InformationBoxFirstLine>
            {"\u2022"} <Trans>oneArticlePerLineString</Trans>
          </InformationBoxFirstLine>
          <InformationBoxSecondLine>
            {"\u2022"} <Trans>scanMultipleTimesToIncreaseQuantityString</Trans>
          </InformationBoxSecondLine>
          <ModalItemWrapper>
            <TextArea
              ref={textAreaRef}
              autoFocus={true}
              label={""}
              value={inputValue}
              rows={35}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
            />
          </ModalItemWrapper>
          {isAddingArticles && <AnimatedLoader />}
        </PaddedHorizontalContainer>
        <ButtonWrapperFixed>
          <ButtonWrapperInner>
            <SkapaButton
              type="primary"
              key="addMultipleArticles"
              text={t("addArticlesString")}
              small={true}
              fluid={true}
              onClick={handleSubmit}
              iconPosition="trailing"
            />
          </ButtonWrapperInner>
        </ButtonWrapperFixed>
        {toastVisible && (
          <SkapaToast
            text={toastText}
            isOpen={toastVisible}
            onCloseRequest={() => {
              setToastVisible(false);
            }}
          />
        )}
      </RoomSettingsPopupContainer>
    </>
  );
}
