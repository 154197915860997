import React from "react";
import styled from "styled-components";
import { useUserPreference } from "../../hooks/useProfilePreferencesQuery";
import profilePreferences from "@coworker/functions/src/enums/profilePreferences.json";
import { useTranslation, Trans } from "@coworker/locales";
import { useFeatureFlagHooks } from "./useMFAQs.featureToggle";
import {
  describeAreaFilter,
  defaultFilters,
  areaIsWholeStore,
  getAreaFilter,
  periodIsCustom,
  getPeriodFilter,
} from "../InputPopup/UserFiltersRow/filterChoices";
import { usePeriodTimestamps } from "../InputPopup/UserFiltersRow/usePeriodTimestamps";
import UserFiltersRow from "../InputPopup/UserFiltersRow/UserFiltersRow";
import { useWorkspacesAction } from "../../hooks/useWorkspaces";
import Instructions from "../Instructions";
import { PositionedLoaderIcon } from "@coworker/reusable/Loader";
import { useStoreId } from "../../core/auth/useLoggedInUser";
import Questions from "./Questions";
import EmptyStateScreen from "../EmptyStateScreen";
import { Button, WideButton } from "@coworker/components";
import { AppBar } from "@coworker/apprestructured/src/layout/components/AppBar/AppBar";
import FixaSearch from "@coworker/apprestructured/src/shared/wrappers/FixaSearch/FixaSearch";

const { DISABLE_POPUPS_MFAQ_SELECT_AREA, MFAQ_PREFIX, USER_FILTERS } =
  profilePreferences;

const ScrollContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding-bottom: 68px;
`;

const ButtonContainer = styled.div`
  width: 15rem;
  margin: auto;
`;

const SectionHeader = styled.div`
  font-weight: bold;
  font-size: 18px;
  height: 60px;
  line-height: 60px;
  padding: 0 24px;

  white-space: nowrap;
  overflow-x: auto;
  -webkit-scrollbar-width: none;
  -moz-scrollbar-width: none;
  -ms-scrollbar-width: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
`;

const ActionBar2 = styled.div`
  position: fixed;
  bottom: 68px;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-block: 10px;
  background-color: white;
  border-top: 1px solid var(--grey200);
`;

const ListSectionHeader = styled(SectionHeader)`
  background-color: var(--grey100);
  padding-top: 8px;
`;

const StyledButton = styled(Button)`
  width: 90%;
`;

const SearchWrapper = styled.div`
  padding: 12px;
  border-bottom: 1px solid var(--grey200);
`;

const TOP_N = 10;

export const CustomerMFAQ = () => {
  const { searchMFAQ, useGetTopQuestions, useGetOtherQuestions } =
    useFeatureFlagHooks();
  const { push } = useWorkspacesAction();
  const [filters] = useUserPreference(
    MFAQ_PREFIX + USER_FILTERS,
    defaultFilters
  );

  const createNew = React.useCallback(
    () => push("/customerquestions/new", { filters }),
    [filters, push]
  );
  const [startDate, endDate] = usePeriodTimestamps(filters);
  const isCustomPeriod = periodIsCustom(getPeriodFilter(filters));
  const endDateObj = new Date(endDate);
  const today = new Date();
  const isEndDateToday =
    endDateObj.getDate() !== today.getDate() ||
    endDateObj.getMonth() !== today.getMonth() ||
    endDateObj.getFullYear() !== today.getFullYear();

  const canVoteInPast = !isCustomPeriod
    ? true
    : isCustomPeriod && !isEndDateToday;

  const [query, setQuery] = React.useState("");
  const [foundQuestions, setFoundQuestions] = React.useState([]);

  const isWholeStoreFilter = areaIsWholeStore(filters);
  const store_id = useStoreId();

  const [from, setFrom] = React.useState(0);
  const [isOtherQuestionsLoaded, setIsOtherQuestionsLoaded] =
    React.useState(false);

  const [shouldShowLoadMoreButton, setShouldShowLoadMoreButton] =
    React.useState(true);

  const { data: otherQuestions } = useGetOtherQuestions(
    startDate,
    endDate,
    getAreaFilter(filters) ?? getAreaFilter(defaultFilters),
    from
  );
  const [questionsList, setQuestionsList] = React.useState([]);
  const prevFrom = React.useRef(from);
  const prevFirstProductId = React.useRef(
    (otherQuestions ?? [])[0]?.question_id
  );
  React.useEffect(() => {
    if (otherQuestions === null || otherQuestions?.length === undefined) return;
    const isPagination = prevFrom.current !== from && from !== 0;
    const pagination = () => {
      setQuestionsList((current) => current.concat(otherQuestions));
      otherQuestions.length < TOP_N
        ? setShouldShowLoadMoreButton(false)
        : setShouldShowLoadMoreButton(true);
      prevFrom.current = from;
      prevFirstProductId.current = otherQuestions[0]?.question_id;
    };

    const updatedFilters = () => {
      setIsOtherQuestionsLoaded(false);
      setQuestionsList(otherQuestions);
      prevFrom.current = 0;
      setFrom(0);
    };

    isPagination ? pagination() : updatedFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherQuestions]);

  const { data: top10Questions, loading: isTop10Loading } = useGetTopQuestions(
    startDate,
    endDate,
    getAreaFilter(filters) ?? getAreaFilter(defaultFilters)
  );
  const scrollContainerRef = React.useRef();

  const searchWithNoHits = query && !foundQuestions?.length;
  const showLoaderIcon = !searchWithNoHits && isTop10Loading;
  const noQuestionsToList =
    top10Questions?.length === 0 && foundQuestions?.length === 0;

  const [showSearch, setShowSearch] = React.useState(false);
  const { t } = useTranslation();

  return (
    <>
      <AppBar title={<Trans>customerQuestionsString</Trans>} />

      <UserFiltersRow
        filters={filters}
        settingsPrefix={profilePreferences.MFAQ_PREFIX}
      />

      <SearchWrapper>
        <FixaSearch
          id="search"
          onChange={async (e) => {
            setQuery(e.target.value);
            const found = await searchMFAQ(
              e.target.value,
              null,
              filters[1],
              store_id
            );
            setFoundQuestions(found);
          }}
          placeholder={t("searchQuestionString")}
          onClear={() => {
            setQuery("");
            setFoundQuestions([]);
            setShowSearch(false);
          }}
        />
      </SearchWrapper>

      {searchWithNoHits && <NoQuestionsFor query={query} />}
      {showLoaderIcon && <PositionedLoaderIcon />}
      {!showLoaderIcon && (
        <ScrollContainer ref={scrollContainerRef}>
          {isWholeStoreFilter && (
            <Instructions
              title={<Trans>mfaqSelectCorrectAreaString</Trans>}
              text={<Trans>mfaqSelectAreaInstructionsString</Trans>}
              type={DISABLE_POPUPS_MFAQ_SELECT_AREA}
              showBottomBorder
            />
          )}
          {noQuestionsToList && !showSearch && (
            <EmptyStateScreen
              titleKey="noQuestionFound"
              subtitleKey="tapOnTheButtonToAddQuestion"
            />
          )}
          {!query && !!top10Questions?.length && (
            <>
              <ListSectionHeader>
                <Trans>top10String</Trans>
                <DashText filters={filters} />
              </ListSectionHeader>
              <Questions
                questions={top10Questions}
                shouldShowLoadMore={false}
                canUpvote={!isWholeStoreFilter && canVoteInPast}
                showUpvote={!isWholeStoreFilter}
              />
              {!isOtherQuestionsLoaded && top10Questions?.length === TOP_N && (
                <ButtonContainer>
                  <WideButton
                    data-testid="loadOtherQuestions"
                    secondary
                    dark
                    text={<Trans>loadMore</Trans>}
                    onClick={() => setIsOtherQuestionsLoaded(true)}
                  />
                </ButtonContainer>
              )}
            </>
          )}
          {!query && isOtherQuestionsLoaded && (
            <>
              <ListSectionHeader>
                <Trans>otherString</Trans>
                <DashText filters={filters} />
              </ListSectionHeader>
              <Questions
                questions={questionsList}
                updateFrom={() => setFrom(from + TOP_N)}
                shouldShowLoadMore={shouldShowLoadMoreButton}
                canUpvote={!isWholeStoreFilter}
                showUpvote={!isWholeStoreFilter}
              />
            </>
          )}
          {query.length > 2 && (
            <Questions
              questions={foundQuestions}
              shouldShowLoadMore={false}
              canUpvote={!isWholeStoreFilter && canVoteInPast}
              showUpvote={!isWholeStoreFilter}
              key="found"
            />
          )}
          <ActionBar2>
            <StyledButton
              data-testid="createNewMFAQ"
              primary
              dark
              text={<Trans>addNewQuestionString</Trans>}
              onClick={createNew}
            />
          </ActionBar2>
        </ScrollContainer>
      )}
    </>
  );
};

export default CustomerMFAQ;

function DashText({ filters }) {
  const area = describeAreaFilter(filters);
  return area ? (
    <span>
      <span>{" – "}</span>
      <span>{area}</span>
    </span>
  ) : (
    <span />
  );
}

const EmptyScreen = styled.div`
  background: var(--grey100);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: calc(100% - 250px); // 100% - (header + filters)

  & > div {
    margin: auto;
    font-size: 14px;
    font-weight: bold;
  }

  & > a {
    text-decoration: underline;
    font-size: 14px;
    height: 50px;
    margin: 0 auto;
    margin-bottom: 60px;
  }
`;

function NoQuestionsFor({ query, createNew }) {
  const { t } = useTranslation();
  return (
    <EmptyScreen>
      <div>{t("noQuestionsForString", { query })}</div>
    </EmptyScreen>
  );
}
